import { Divider } from "antd";
import "./index.css";
import CardDaily from "./CardDaily";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { getUserHistoryStore } from "stores/baiviet";
import { useTranslation } from "react-i18next";
// import { useModel } from 'umi';
// import { useEffect } from "react";

const HistorySearch = () => {
  const { t } = useTranslation();
  const baiViet = useAppSelector((state) => state.baiviet);
  const { arrUserHis } = baiViet;
  const dispatch = useAppDispatch();
  useEffect(() => {
    //@ts-ignore
    dispatch(
      getUserHistoryStore({
        //@ts-ignore
        token: localStorage.getItem("token") ?? null,
      })
    )
      .then(unwrapResult)
      .then((result) => {});
  }, []);

  return (
    <div className="divTo">
      <div className="divTitle">{t("titleHistory")}</div>
      <div className="divDes">{t("desHistory")}</div>
      <Divider />
      <CardDaily data={arrUserHis} />
    </div>
  );
};

export default HistorySearch;
