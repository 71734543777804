import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBenhLy,
  getHistorySearch,
  searchBenhLy,
  searchBenhLyHeader,
  getSavedPost,
  getPostById,
  savePost,
  delSavedPost,
  getUserHistory,
  postBenhLy,
  getEdittBenhLy,
  editBenhLy,
  delPost,
  createHistoryKeyfind,
  getPostHistory,
  getPostByIdLoading,
  getPostLink,
  getBenhLyHome,
  getArrChuyenKhoa,
} from "services/benhly";

export interface IRecordBenhLy {
  _id: string;
  original_post: string;
  translation_post: string;
  link: string;
  is_active: boolean;
  specialist: string;
  summary: string;
  point: {
    less10: number;
    form10to20: number;
    form20to30: number;
    form30to40: number;
    form40to50: number;
    form50to60: number;
    bigger60: number;
    total: number;
  };
  data: IData[];
}

export interface IData {
  name: string;
  level: string;
}

export interface IPayloadSearch {
  full_name?: string;
  username?: string;
  age?: number;
  sick?: [string];
  post_id?: string;
  key_find?: string;
  token?: string;
}

export interface IPayloadGetById {
  token?: string;
  post_id?: string;
}

const initialState: any = {
  loading: false,
  count: 0,
  page: 0,
  limit: 12,
  arrPost: [],
  total: 0,
  arrKeySearch: [],
  keySearch: null,
  arrPostSaved: [],
  totalPostSave: 0,
  record: {},
  arrUserHis: [],
  loadingPost: false,
  loadingButton: false,
  loadingPostData: false,
  trangThai: null,
  chuyenKhoa: null,
  ngonNgu: "vi",
  key_find: null,
  postAdmin: [],
  totalAdmin: 0,
  visibleMenu: true,
};

export const getArrChuyenKhoaStore = createAsyncThunk(
  "benhly/getArrChuyenKhoa",
  async (payload: any) => {
    const response = await getArrChuyenKhoa(payload);
    return response?.data;
  }
);

export const getPostLinkStore = createAsyncThunk(
  "benhly/getPostLink",
  async (payload: any) => {
    const response = await getPostLink(payload);
    return response?.data;
  }
);

export const getPostHistoryStore = createAsyncThunk(
  "benhly/getPostHistory",
  async () => {
    const response = await getPostHistory();
    return response?.data;
  }
);

export const createHistoryKeyfindStore = createAsyncThunk(
  "benhly/createHistoryKeyfind",
  async (payload: { data: string }) => {
    const response = await createHistoryKeyfind(payload);
    return response?.data;
  }
);

export const getBenhLyStore = createAsyncThunk(
  "benhly/getBenhLyHome",
  async (payload: {
    token: string | undefined;
    page: number;
    limit: number;
    isActive: boolean;
  }) => {
    const response = await getBenhLyHome(payload);
    return response?.data;
  }
);

export const getBenhLyAdmin = createAsyncThunk(
  "benhly/getBenhLy",
  async (payload: any) => {
    const response = await getBenhLy(payload);
    return response?.data;
  }
);

export const getKeySearchStore = createAsyncThunk(
  "benhly/getHistorySearch",
  async (payload: { token: string | undefined }) => {
    const response = await getHistorySearch(payload);
    return response?.data;
  }
);

export const searchBenhLyStore = createAsyncThunk(
  "benhly/searchBenhLy",
  async (payload: { token: string | undefined }) => {
    const response = await searchBenhLy(payload);
    return response?.data;
  }
);

export const searchBenhLyHeaderStore = createAsyncThunk(
  "benhly/searchBenhLyHeader",
  async (payload: any) => {
    const response = await searchBenhLyHeader(payload);
    return response?.data;
  }
);

export const getSavedPostStore = createAsyncThunk(
  "benhly/getSavedPost",
  async (payload: {
    token: string | undefined;
    page: number;
    limit: number;
  }) => {
    const response = await getSavedPost(payload);
    return response?.data;
  }
);

export const getPostByIdStore = createAsyncThunk(
  "benhly/getPostById",
  async (payload: { token: string | undefined; post_id: string }) => {
    const response = await getPostById(payload);
    return response?.data;
  }
);

export const getPostByIdLoadingStore = createAsyncThunk(
  "benhly/getPostByIdLoading",
  async (payload: { token: string | undefined; post_id: string }) => {
    const response = await getPostByIdLoading(payload);
    return response?.data;
  }
);

export const savePostStore = createAsyncThunk(
  "benhly/savePost",
  async (payload: { token: string | undefined; post_id: string }) => {
    const response = await savePost(payload);
    return response?.data;
  }
);

export const delSavedPostStore = createAsyncThunk(
  "benhly/delSavedPost",
  async (payload: { token: string | undefined; post_id: string }) => {
    const response = await delSavedPost(payload);

    return response?.data;
  }
);

export const getUserHistoryStore = createAsyncThunk(
  "benhly/getUserHistory",
  async (payload: { token: string | undefined }) => {
    const response = await getUserHistory(payload);
    return response?.data;
  }
);

export const postBenhLyStore = createAsyncThunk(
  "benhly/postBenhLy",
  async (payload: any) => {
    const response = await postBenhLy(payload);
    return response?.data;
  }
);

export const getEdittBenhLyStore = createAsyncThunk(
  "benhly/getEdittBenhLy",
  async (payload: any) => {
    const response = await getEdittBenhLy(payload);
    return response?.data;
  }
);

export const editBenhLyStore = createAsyncThunk(
  "benhly/editBenhLy",
  async (payload: any) => {
    const response = await editBenhLy(payload);
    return response?.data;
  }
);

export const delPostStore = createAsyncThunk(
  "benhly/delPost",
  async (payload: any) => {
    const response = await delPost(payload);
    return response?.data;
  }
);

const postSlice = createSlice({
  name: "post",
  initialState: initialState,
  reducers: {
    setKeySearch: (state, val) => {
      state.keySearch = val.payload;
    },

    setPage: (state, val) => {
      state.page = val.payload;
    },

    setTrangThai: (state, val) => {
      state.trangThai = val.payload;
    },

    setChuyenKhoa: (state, val) => {
      state.chuyenKhoa = val.payload;
    },

    setNgonNgu: (state, val) => {
      state.ngonNgu = val.payload;
    },

    setKeyFind: (state, val) => {
      state.key_find = val.payload;
    },

    setVisibleMenu: (state, val) => {
      state.visibleMenu = val.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBenhLyStore.pending, (state) => {
      state.loadingPost = true;
      state.loading = true;
    });

    builder.addCase(getBenhLyStore.fulfilled, (state, actions: any) => {
      state.data = actions.payload;
      state.arrPost = actions.payload.data ?? [];
      state.total = actions.payload.total ?? 0;

      state.page = actions.meta.arg.page;
      state.limit = actions.meta.arg.limit;
      state.count = actions.meta.arg.count ?? 0;
      state.loadingPost = false;
      state.loading = false;
    });

    builder.addCase(getBenhLyStore.rejected, (state) => {
      state.loadingPost = false;
      state.loading = false;
    });

    builder.addCase(getPostByIdLoadingStore.pending, (state) => {
      state.loadingPostData = true;
    });

    builder.addCase(
      getPostByIdLoadingStore.fulfilled,
      (state, actions: any) => {
        state.loadingPostData = false;
        state.record = actions.payload.data;
      }
    );

    builder.addCase(getPostHistoryStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getPostHistoryStore.fulfilled, (state, actions: any) => {
      state.loading = false;
    });

    builder.addCase(createHistoryKeyfindStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      createHistoryKeyfindStore.fulfilled,
      (state, actions: any) => {
        state.loading = false;
      }
    );

    builder.addCase(getBenhLyAdmin.pending, (state) => {
      state.loadingPost = true;
      state.loading = true;
    });

    builder.addCase(getBenhLyAdmin.fulfilled, (state, actions: any) => {
      state.data = actions.payload;
      state.postAdmin = actions.payload.data ?? [];
      state.totalAdmin = actions.payload.total ?? 0;
      state.loadingPost = false;
      state.loading = false;
    });

    builder.addCase(postBenhLyStore.pending, (state) => {
      state.loadingButton = true;
    });

    builder.addCase(postBenhLyStore.fulfilled, (state, actions: any) => {
      state.loadingButton = false;
    });

    builder.addCase(getKeySearchStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getKeySearchStore.fulfilled, (state, actions: any) => {
      state.loading = false;
      state.arrKeySearch = actions.payload.data ?? [];
    });

    builder.addCase(searchBenhLyStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(searchBenhLyStore.fulfilled, (state, actions: any) => {
      state.loading = false;

      state.arrPost = actions.payload.data ?? [];
      state.total = actions.payload.total ?? 0;

      state.page = actions.meta.arg.page;
      state.limit = actions.meta.arg.limit;
      state.count = actions.meta.arg.count;
    });

    // builder.addCase(searchBenhLyHeaderStore.pending, (state) => {
    //   state.loading = true;
    // });

    // builder.addCase(
    //   searchBenhLyHeaderStore.fulfilled,
    //   (state, actions: any) => {
    //     state.loading = false;
    //     state.arrKeySearch = actions.payload.data ?? [];
    //   }
    // );

    builder.addCase(getSavedPostStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSavedPostStore.fulfilled, (state, actions: any) => {
      state.loading = false;

      state.arrPostSaved = actions.payload.data ?? [];
      state.totalPostSaved = actions.payload.total ?? 0;

      state.page = actions.meta.arg.page;
    });

    builder.addCase(getPostByIdStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getPostByIdStore.fulfilled, (state, actions: any) => {
      state.loading = false;
      state.record = actions.payload.data;
    });

    builder.addCase(savePostStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(savePostStore.fulfilled, (state, actions: any) => {
      state.loading = false;
    });

    builder.addCase(delSavedPostStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(delSavedPostStore.fulfilled, (state, actions: any) => {
      state.loading = false;
    });

    builder.addCase(getUserHistoryStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserHistoryStore.fulfilled, (state, actions: any) => {
      state.loading = false;
      state.arrUserHis = actions.payload;
    });

    builder.addCase(getEdittBenhLyStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getEdittBenhLyStore.fulfilled, (state, actions: any) => {
      state.loading = false;
      state.record = actions.payload;
    });

    builder.addCase(editBenhLyStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(editBenhLyStore.fulfilled, (state, actions: any) => {
      state.loading = false;
    });

    builder.addCase(delPostStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(delPostStore.fulfilled, (state, actions: any) => {
      state.loading = false;
    });

    builder.addCase(searchBenhLyHeaderStore.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      searchBenhLyHeaderStore.fulfilled,
      (state, actions: any) => {
        state.loading = false;
        state.arrKeySearch = actions.payload.data ?? [];
      }
    );
  },
});

export const {
  setKeySearch,
  setNgonNgu,
  setChuyenKhoa,
  setKeyFind,
  setTrangThai,
  setVisibleMenu,
  setPage,
} = postSlice.actions;

export default postSlice.reducer;
