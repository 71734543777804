import {
  LogoutOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppDispatch } from "../../hooks/index";
import { useNavigate } from "react-router-dom";
import { logout } from "stores/user";

const DropdownAction = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <p>
        <UserOutlined /> Trang cá nhân
      </p>
      <p>
        <UnlockOutlined /> Đổi mật khẩu
      </p>
      <p
        onClick={() => {
          dispatch(logout());
          navigate("/login");
        }}
      >
        <LogoutOutlined />
        Đăng xuất
      </p>
    </div>
  );
};

export default DropdownAction;
