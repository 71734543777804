/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
// import { Button, Modal } from 'antd';

import
  {
    DownOutlined,
    LogoutOutlined,
    SaveOutlined,
    SettingOutlined,
    UnorderedListOutlined
  } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import
  {
    AutoComplete,
    Button,
    Divider,
    Dropdown,
    Menu,
    Modal,
    Select,
    Space
  } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import FormLogin from "pages/User/FormLogin";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import
  {
    createHistoryKeyfindStore,
    searchBenhLyHeaderStore,
    setKeySearch
  } from "stores/baiviet";
import { getCurrent, logout, setIsLogin } from "stores/user";
import "./index.css";

const Nav = () => {
  let timeout: any = 0;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const users = useAppSelector((store) => store.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const [data, setData] = useState([]);

  const { Option } = AutoComplete;

  // const { user_name, user_type } = users.data;

  const user_name = localStorage.getItem("user_name");
  const user_type = localStorage.getItem("user_type");
  useEffect(() => {
    //@ts-ignore
    dispatch(getCurrent({ token: localStorage.getItem("token") }));
    // setKey(keySearch);
    // eslint-disable-next-line
  }, [window.location.pathname.split("/")?.[2]]);

  const handleCancel = () => {
    dispatch(setIsLogin(false));
  };

  const dangXuat = () => {
    dispatch(logout());
  };

  const handleChangeLang = (e: any) => {
    i18n.changeLanguage(e);
    localStorage.setItem("lang", e);
    navigate(`/`);
  };

  const handleClick = async (e: any) => {
    if (e) {
      setLoading(true);
      let txt = e;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (txt.length >= 3) {
          // setKey(txt);
          dispatch(
            searchBenhLyHeaderStore({
              key_find: txt,
              language: i18n.language,
            })
          )
            .then(unwrapResult)
            .then((result) => {
              setData(result);
              setLoading(false);
            });
        } else {
          // setKey(txt);
        }
      }, 500);
    } else {
      setData([]);
      // setKey(null);
    }
  };

  const handleSearch = async (check: any, e: any) => {
    // setId(e.key);
    dispatch(
      await createHistoryKeyfindStore({
        //@ts-ignore
        data: e.key,
      })
    )
      .then(unwrapResult)
      .then((result) => {});

    navigate(`../post/${e.key}`);
  };

  return (
    // <Affix offsetTop={1}>
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        position: "fixed",
        top: 0,
        width: "100%",
        background: "white",
        zIndex: 999,
      }}
    >
      <div>
        <div className="divnav">
          <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            <img src="/logo.png" alt="image" className="widthAnh" />
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="divSearch"
          >
            <AutoComplete
              style={{ width: "100%" }}
              onSearch={handleClick}
              placeholder={t("placeholder.search")}
              onSelect={handleSearch}
              //@ts-ignore
              loading={loading}
            >
              {data?.map((item: any) => (
                <Option
                  key={item._id}
                  value={item.original_post}
                  onClick={async () => {
                    let element = document.getElementById("inputSearch");
                    if (element) {
                      //@ts-ignore
                      element.value = item.original_post;
                    }
                    // setId(item._id);
                    // setKey(item.original_post);
                    dispatch(setKeySearch(item.original_post));
                    await handleSearch(false, item._id);
                  }}
                >
                  {item.original_post}
                </Option>
              ))}
            </AutoComplete>

            <Button
              // onClick={handleSearch}
              style={{
                background: "#005884",
                height: "100%",
                borderRadius: "0 15px 15px 0",
                width: "50px",
              }}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z"
                    fill="white"
                  />
                  <mask
                    id="mask0_553_13703"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x={2}
                    y={2}
                    width={16}
                    height={16}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_553_13703)">
                    <rect width={20} height={20} fill="white" />
                  </g>
                </svg>
              }
            ></Button>
          </div>
          <div className="divbtnMobile">
            {!user_name && (
              <Button
                type="primary"
                className="btnLogin"
                onClick={() => dispatch(setIsLogin(true))}
              >
                {t("button.login")}
              </Button>
            )}

            {user_name && user_type !== "ADMIN" && (
              <>
                <Dropdown
                  overlay={
                    <Menu>
                      {/* <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/user"
                        >
                          <UserOutlined style={{ marginRight: "5px" }} />
                          Chi tiết hồ sơ
                        </a>
                      </Menu.Item> */}
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" href="/user/post_saved">
                          <SettingOutlined style={{ marginRight: "5px" }} />
                          {t("button.login")}
                        </a>
                      </Menu.Item>
                      {/* <Menu.Item key="2">
                        <a rel="noopener noreferrer" href="/user/history">
                          <MailOutlined style={{ marginRight: "5px" }} />
                          Lịch sử hoạt động
                        </a>
                      </Menu.Item> */}
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "5px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <img src="/user.png" style={{ marginRight: "10px" }} />
                </Dropdown>
              </>
            )}

            {user_name && user_type === "ADMIN" && (
              <>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/admin/manage_post"
                        >
                          <UnorderedListOutlined
                            style={{ marginRight: "10px" }}
                          />
                          {t("button.managePost")}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "10px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <img src="/user.png" style={{ marginRight: "10px" }} />
                </Dropdown>
              </>
            )}
          </div>

          {!user_name && (
            <div className="divbtn">
              <Select
                defaultValue={localStorage.getItem("lang")}
                onChange={handleChangeLang}
              >
                <Select.Option value="vi">
                  <img src="/vi.png" height={"20px"} />
                </Select.Option>
                <Select.Option value="en">
                  <img src="/en.png" height={"20px"} />
                </Select.Option>
              </Select>
              <img
                src="/line.png"
                style={{ marginLeft: "20px", marginRight: "20px" }}
              />
              <Button
                type="primary"
                className="btnLogin"
                onClick={() => dispatch(setIsLogin(true))}
              >
                {t("button.login")}
              </Button>
            </div>
          )}
          {user_name && user_type !== "ADMIN" && (
            <>
              <div className="divbtn" style={{ cursor: "pointer" }}>
                <Select
                  defaultValue={localStorage.getItem("lang")}
                  onChange={handleChangeLang}
                >
                  <Select.Option value="vi">
                    <img src="/vi.png" height={"20px"} />
                  </Select.Option>
                  <Select.Option value="en">
                    <img src="/en.png" height={"20px"} />
                  </Select.Option>
                </Select>
                <img
                  src="/line.png"
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                />
                <img src="/user.png" style={{ marginRight: "10px" }} />

                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/user/post_saved"
                        >
                          <SaveOutlined style={{ marginRight: "10px" }} />
                          {t("button.postSaved")}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "10px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Space className="txtusername">
                    {user_name}
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Space>
                </Dropdown>
              </div>
            </>
          )}

          {user_name && user_type === "ADMIN" && (
            <>
              <div className="divbtn" style={{ cursor: "pointer" }}>
                <Select
                  defaultValue={localStorage.getItem("lang")}
                  onChange={handleChangeLang}
                >
                  <Select.Option value="vi">
                    <img src="/vi.png" height={"20px"} />
                  </Select.Option>
                  <Select.Option value="en">
                    <img src="/en.png" height={"20px"} />
                  </Select.Option>
                </Select>
                <img
                  src="/line.png"
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                />
                <img src="/user.png" style={{ marginRight: "10px" }} />

                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/admin/manage_post"
                        >
                          <UnorderedListOutlined
                            style={{ marginRight: "10px" }}
                          />
                          {t("button.managePost")}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "10px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Space className="txtusername">
                    {user_name}
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Space>
                </Dropdown>
              </div>
            </>
          )}

          <Modal
            title={t("button.login")}
            visible={users.isLogin}
            footer={null}
            onCancel={() => dispatch(setIsLogin(false))}
            // onCancel={handleCancel}
            className="modal"
          >
            <FormLogin cancel={handleCancel} />
          </Modal>
        </div>
        <Divider />
      </div>
    </div>
    // </Affix>
  );
};

export default Nav;
