import { DownOutlined, UpOutlined, UserOutlined } from "@ant-design/icons";
import routes from "config/route";
import { IRoute } from "config/route.d";
import { Link } from "react-router-dom";
import { NavbarWrapper } from "./index.style";
import { ReactNode, useState } from "react";
import { useReactPath } from "../index";
import defaultConfig from "config/defaultConfig";

const LiCollapse = ({
  children,
  route,
  key,
  style,
}: {
  children: ReactNode;
  route: IRoute;
  key: string;
  style: object;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <li key={key} style={style}>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        onClick={() => setOpen(!open)}
      >
        <span>
          {route.icon} {route.title}
        </span>
        {!open && <DownOutlined />}
        {open && <UpOutlined />}
      </div>
      {open && children}
    </li>
  );
};

const Navbar = () => {
  const offsetPadding = 10;
  const pathname = useReactPath();
  const renderNavbar = (route: Array<IRoute>, paddingLeft: number) => {
    return (
      <>
        {route
          .filter((item) => item.hideInMenu !== true)
          .map((item) => {
            if (!item.routes || (item.routes && item.routes.length < 1)) {
              return (
                <li
                  key={item.path}
                  style={{
                    paddingLeft,
                    ...(pathname === item.path && {
                      backgroundColor: defaultConfig.mainColor,
                    }),
                  }}
                >
                  <Link to={item.path}>
                    {item.icon} {item.title}
                  </Link>
                </li>
              );
            } else {
              const childRoutes = item.routes?.map((e) => ({
                ...e,
                path: item.path + e.path,
              }));
              return (
                <LiCollapse
                  key={item.path}
                  route={item}
                  children={
                    <ul>
                      {renderNavbar(
                        childRoutes ?? [],
                        paddingLeft + offsetPadding
                      )}
                    </ul>
                  }
                  style={{ paddingLeft }}
                />
              );
            }
          })}
      </>
    );
  };
  return <NavbarWrapper>{renderNavbar(routes, 0)}</NavbarWrapper>;
};

export default Navbar;
