import React, { useEffect, useState } from "react";
import "./index.css";
import packageJson from "../../../package.json";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPostHistoryStore } from "stores/baiviet";
import { useAppDispatch } from "hooks";

const Footer = () => {
  const token = localStorage.getItem("token");
  const [length, setLength] = useState(0);
  const dispatch = useAppDispatch();
  useEffect(() => {
    //@ts-ignore
    if (token) {
      dispatch(getPostHistoryStore())
        .then(unwrapResult)
        .then((result) => {
          setLength(result?.length);
        });
    }
  }, []);
  return (
    <div
      className="divPostFooter"
      style={{ marginTop: token && length > 6 ? "10vh" : "26vh" }}
    >
      <div className="divConPost">
        <a
          style={{ marginBottom: 0, color: "white" }}
          href="https://medihome.com.vn/"
          target="_blank"
        >
          © Copyright - Medihome (v{packageJson.version})
        </a>
      </div>
    </div>
  );
};

export default Footer;
