const data: any = {
  path: {
    'Học viên': '/lab-ao-hoa',
    'Giảng viên': '/lop-giang-day/thuc-hanh',
    Admin: '/',
  },
  error: {
    BAD_REQUEST_DEVICE_IDENDIFIED: 'BAD_REQUEST_DEVICE_IDENDIFIED',
    BAD_REQUEST_WRONG_PASSWORD: 'Sai mật khẩu',
    BAD_REQUEST_EMPTY_CLIENT_DEVICE_ID: 'BAD_REQUEST_EMPTY_CLIENT_DEVICE_ID',
    BAD_REQUEST_CLIENT_DEVICE_ID_EXIST: 'BAD_REQUEST_CLIENT_DEVICE_ID_EXIST',
    BAD_REQUEST_WRONG_OLD_PASSWORD: 'Mật khẩu cũ không đúng',
    BAD_REQUEST_DUPLICATE_NEW_PASSWORD: 'Mật khẩu mới giống mật khẩu cũ',
    BAD_REQUEST_DUPLICATE_EMAIL: 'Đã tồn tại tài khoản sử dụng email này',
    UNAUTHORIZED_WRONG_PASSWORD: 'Tên tài khoản hoặc mật khẩu chưa chính xác',
    UNAUTHORIZED_USERNAME_NOT_FOUND: 'Tên tài khoản hoặc mật khẩu chưa chính xác',
  },

  arrChuyenKhoa: [
    {
      ten: 'Khoa Khám bệnh',
      ma: 'K01',
    },
    {
      ten: 'Khoa Hồi sức cấp cứu',
      ma: 'K02',
    },
    {
      ten: 'Khoa Nội tổng hợp',
      ma: 'K03',
      url:'/K03.png'
    },
    {
      ten: 'Khoa Nội tim mạch',
      ma: 'K04',
    },

    {
      ten: 'Khoa Nội tiêu hoá',
      ma: 'K05',
    },
    {
      ten: 'Khoa Nội cơ - xương - khớp',
      ma: 'K06',
      url:'/K06.png'
    },
    {
      ten: 'Khoa Nội thận - tiết niệu',
      ma: 'K07',
    },
    {
      ten: 'Khoa Nội tiết',
      ma: 'K08',
      url:'/K08.png'
    },

    {
      ten: 'Khoa Dị ứng',
      ma: 'K09',
    },
    {
      ten: 'Khoa Huyết học lâm sàng',
      ma: 'K10',
    },
    {
      ten: 'Khoa Truyền nhiễm',
      ma: 'K12',
    },

    {
      ten: 'Khoa Lao',
      ma: 'K13',
      url:'/K13.png'
    },
    {
      ten: 'Khoa Da liễu',
      ma: 'K14',
    },
    {
      ten: 'Khoa Thần kinh',
      ma: 'K15',
    },
    {
      ten: 'Khoa Tâm thần',
      ma: 'K16',
    },

    {
      ten: 'Khoa Y học cổ truyền',
      ma: 'K17',
      url:'/K17.png'
    },
    {
      ten: 'Khoa Lão học',
      ma: 'K18',
      url:'/K18.png'
    },
    {
      ten: 'Khoa Nhi',
      ma: 'K19',
      url:'/K19.png'
    },
    {
      ten: 'Khoa Ngoại tổng hợp',
      ma: 'K20',
    },

    {
      ten: 'Khoa Ngoại thần kinh',
      ma: 'K21',
    },
    {
      ten: 'Khoa Ngoại lồng ngực',
      ma: 'K22',
    },
    {
      ten: 'Khoa Ngoại tiêu hoá',
      ma: 'K23',
    },
    {
      ten: 'Khoa Ngoại thận - tiết niệu',
      ma: 'K24',
    },

    {
      ten: 'Khoa Chấn thương chỉnh hình',
      ma: 'K25',
    },
    {
      ten: 'Khoa Bỏng',
      ma: 'K26',
    },
    {
      ten: 'Khoa Phụ sản',
      ma: 'K27',
      url:'/K27.png'
    },
    {
      ten: 'Khoa Tai - Mũi - Họng',
      ma: 'K28',
      url:'/K28.png'
    },

    {
      ten: 'Khoa Răng - Hàm - Mặt',
      ma: 'K29',
      url:'/K29.png'
    },
    {
      ten: 'Khoa Mắt',
      ma: 'K30',
      url:'/K30.png'
    },
    {
      ten: 'Khoa Vật lý trị liệu - Phục hồi chức năng',
      ma: 'K31',
    },
    {
      ten: 'Khoa Y học hạt nhân',
      ma: 'K32',
    },

    {
      ten: 'Khoa Ung bướu (điều trị tia xạ)',
      ma: 'K33',
    },
    {
      ten: 'Khoa Truyền máu',
      ma: 'K34',
    },
    {
      ten: 'Khoa Lọc máu nhân tạo',
      ma: 'K35',
    },
    {
      ten: 'Khoa Huyết học',
      ma: 'K36',
    },

    {
      ten: 'Khoa Sinh hoá',
      ma: 'K37',
    },
    {
      ten: 'Khoa Vi sinh',
      ma: 'K38',
    },
    {
      ten: 'Khoa Chẩn đoán hình ảnh',
      ma: 'K39',
      url:'/K39.png'
    },
    {
      ten: 'Khoa Thăm dò chức năng',
      ma: 'K40',
    },

    {
      ten: 'Khoa Nội soi',
      ma: 'K41',
    },
    {
      ten: 'Khoa Giải phẫu bệnh',
      ma: 'K42',
    },
    {
      ten: 'Khoa Chống nhiễm khuẩn',
      ma: 'K43',
    },
    {
      ten: 'Khoa Dược',
      ma: 'K44',
    },

    {
      ten: 'Khoa Dinh dưỡng',
      ma: 'K45',
    },
    {
      ten: 'Khoa Sinh học phân tử',
      ma: 'K46',
    },
    {
      ten: 'Khoa Xét nghiệm',
      ma: 'K47',
    },
    {
      ten: 'Khoa hồi sức tích cực',
      ma: 'K48',
    },

    {
      ten: 'Khoa Chống độc',
      ma: 'K49',
    },
    {
      ten: 'Khoa Nội hô hấp',
      ma: 'K50',
    },
  ],

   arrQuyen: [
    {
      name: 'ORG_USER',
      comment: 'Bệnh nhân',
    },
    {
      name: 'ORG_OPERATOR',
      comment: 'CSKH hệ thống',
    },
    {
      name: 'DOCTOR',
      comment: 'Bác sĩ',
    },
    {
      name: 'ORG_EXTERNAL',
      comment: 'Tích hợp',
    },
    {
      name: 'SITE_CASHIER',
      comment: 'Thu ngân, thẩm định',
    },
    {
      name: 'SITE_RECEIPTION',
      comment: 'Lễ tân',
    },
    {
      name: 'ORG_UTILS',
      comment: 'SYSTEM',
    },
    {
      name: 'SITE_ADMIN',
      comment: 'Quản trị viên tại CSYT',
    },
    {
      name: 'SITE_OPERATOR',
      comment: 'CSKH tại CSYT',
    },
    {
      name: 'SITE_INTEGRATION',
      comment: 'Tài khoản tích hợp',
    },
    {
      name: 'ORG_ADMIN',
      comment: 'Quản trị hệ thống',
    },
    {
      name: 'ORG_DICTIONARY',
      comment: 'For Medihome Dictionary',
    },
    {
      name: 'PUBLIC',
      comment: 'Public',
    },
  ],
};

export default data;
