import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "stores/user";

const ProtectedPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <>
      <h1>Protected page</h1>
      {/* <p>User: {user?.data?.name}</p> */}
      <button
        onClick={() => {
          dispatch(logout());
          navigate("/login");
        }}
      >
        Lougout
      </button>
    </>
  );
};

export default ProtectedPage;
