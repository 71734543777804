/* eslint-disable @typescript-eslint/no-unused-expressions */
// axios.js
import { notification } from "antd";
import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    if (config.url === process.env.REACT_APP_IP_LOGIN) {
      //@ts-ignore
      config.headers.Authorization = `Basic ${process.env.REACT_APP_BASIC_HEADER}`;
    }

    if (config.url === process.env.REACT_APP_IP_CHECK_TOKEN) {
      //@ts-ignore
      config.headers.Authorization = `Basic ${process.env.REACT_APP_BASIC_HEADER}`;
    } else {
      if (
        // config.baseURL === baseApiAddress &&
        config &&
        config.headers &&
        !config.headers.Authorization
      ) {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          // config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  async function (error) {
    const res = { error };
    //@ts-ignore
    switch (res?.error?.response?.status) {
      case 400:
        if (res?.error?.config?.url === process.env.REACT_APP_IP_CHECK_TOKEN) {
          const form = new FormData();

          // @ts-ignore
          form.append("refresh_token", localStorage.getItem("refresh_token"));
          // @ts-ignore
          form.append("grant_type", "refresh_token");

          const response = await axios({
            method: "post",
            url: process.env.REACT_APP_IP_LOGIN,
            data: form,
            headers: {
              Authorization: `Basic ${process.env.REACT_APP_BASIC_HEADER}`,
              "Content-Type":
                "multipart/form-data; boundary=----WebKitFormBoundaryxHDUDIt26ZAKSsax",
            },
          });

          localStorage.setItem("token", response.data.access_token ?? "");
          localStorage.setItem(
            "refresh_token",
            response.data.refresh_token ?? ""
          );
          localStorage.setItem("user_type", response.data.user_type ?? "");

          const newForm = new FormData();

          newForm.append("token", response.data.access_token);

          axios({
            method: res.error.config.method,
            url: res.error.config.url,
            data: newForm,
            headers: res.error.config.header,
          });
        }
        // if (res?.error?.response?.data?.error === "invalid_grant") {
        //   notification.error({
        //     message: "Thông báo",
        //     description: "Phiên đăng nhập hết hạn. Vui lòng đăng nhập lại",
        //     duration: 5000,
        //   });
        //   localStorage.clear();
        //   // window.location.assign("/");
        // }
        if (
          res?.error?.response?.data?.error_description === "Bad credentials"
        ) {
          notification.error({
            message: "Thông báo",
            description: "Đăng nhập không đúng tài khoản",
          });
          localStorage.clear();
        }
        break;
      case 401:
        if (res?.error?.config?.url === process.env.REACT_APP_IP_LOGIN) {
          notification.error({
            message: "Thông báo",
            description: "Phiên đăng nhập hết hạn. Vui lòng đăng nhập lại.",
            duration: 5000,
          });
          localStorage.clear();

          window.location.assign("/");
        } else {
          const form = new FormData();

          // @ts-ignore
          form.append("refresh_token", localStorage.getItem("refresh_token"));
          // @ts-ignore
          form.append("grant_type", "refresh_token");
          return new Promise(async function (resolve, reject) {
            const response = await axios({
              method: "post",
              url: process.env.REACT_APP_IP_LOGIN,
              data: form,
              headers: {
                Authorization: `Basic ${process.env.REACT_APP_BASIC_HEADER}`,
                "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundaryxHDUDIt26ZAKSsax",
              },
            });

            localStorage.setItem("token", response.data.access_token ?? "");
            localStorage.setItem(
              "refresh_token",
              response.data.refresh_token ?? ""
            );
            localStorage.setItem("user_type", response.data.user_type ?? "");

            let a = res.error.config.headers;
            a.Authorization = `Bearer ${response.data.access_token}`;

            resolve(
              axios({
                method: res.error.config.method,
                url: res.error.config.url,
                data: res.error.config.data,
                headers: a,
              })
            );
          });
        }
        break;

      case 403:
        notification.error({
          message: "Không được truy cập",
          description: error?.message,
        });
        break;

      case 404:
        notification.error({
          message:
            "Lỗi không tìm thấy dữ liệu, bạn hãy thử f5 refresh lại trình duyệt để cập nhật phiên bản mới nhất.",
          description: error?.message,
        });
        break;

      case 405:
        notification.error({
          message: "Truy vấn không được phép",
          description: error?.message,
        });
        break;

      case 409:
        notification.error({
          message: "Dữ liệu chưa đúng",
          description: error?.message,
        });
        76;
        break;

      case 500:
        if (
          res?.error?.response?.data?.error_description ===
          "000 | User not found"
        ) {
          notification.error({
            message: "Thông báo",
            description: "Đăng nhập không đúng tài khoản",
          });
        } else {
          notification.error({
            description: "Server gặp lỗi",
            message: "Thông báo",
          });
        }

        break;

      default:
        break;
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
