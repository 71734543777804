
const LoginPage = () => {

  return (
    <>
      <h1>Login Page</h1>
      <button
        onClick={() => {
          // if ((user?.data?.name ?? "") !== "") {
          //   dispatch(logout());
          // } else {
          //   //@ts-ignore
          //   dispatch(login());
          // }
        }}
      >
        {" "}
        {/* {(user?.data?.name ?? "") !== "" ? "Logout" : "Login"} */}
      </button>
    </>
  );
};

export default LoginPage;
