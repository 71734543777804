import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Input, Select } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import
  {
    getBenhLyAdmin, setChuyenKhoa,
    setKeyFind, setNgonNgu, setTrangThai, getArrChuyenKhoaStore
  } from "stores/baiviet";
import data from "./../../../utils/data";
import "./index.css";
import { useEffect, useState } from "react";

// import { history } from 'umi';

const Search = () => {
  let timeout: any = 0;
  const dispatch = useAppDispatch();
  const baiViet = useAppSelector((state) => state.baiviet);
  const { trangThai, ngonNgu, chuyenKhoa, key_find } = baiViet;
  const [arrChuyenKhoa, setArrChuyenKhoa] = useState<any>([]);
  const navigate = useNavigate();
  const arrStatus = [
    { title: "Tất cả", val: null },
    { title: "Đã đăng", val: true },
    { title: "Tạm ẩn", val: false },
  ];

  const arrLang = [
    { title: "Tiếng Việt", val: "vi" },
    { title: "Tiếng Anh", val: "en" },
  ];

  const handleChange = async (type: any, val: any) => {
    if (type === "ngonNgu") {
      dispatch(setNgonNgu(val));
      dispatch(setChuyenKhoa('Tất cả'));
      dispatch(
        getArrChuyenKhoaStore({
          //@ts-ignore
          lang: val,
        })
      )
        .then(unwrapResult)
        .then((result) =>
        {
          setArrChuyenKhoa(result?.content_page);
        });
      await dispatch(
        getBenhLyAdmin({
          page: 0,
          limit: 10,
          language: val,
          specialization: null,
          key_find: key_find,
          is_active: trangThai,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          dispatch(setNgonNgu(val));
        });
    }

    if (type === "trangThai") {
      dispatch(setTrangThai(val));
      await dispatch(
        getBenhLyAdmin({
          page: 0,
          limit: 10,
          language: ngonNgu,
          specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
          key_find: key_find,
          is_active: val,
        })
      )
        .then(unwrapResult)
        .then((result) => {});
    }

    if (type === "chuyenKhoa") {
      dispatch(setChuyenKhoa(val));
      await dispatch(
        getBenhLyAdmin({
          page: 0,
          limit: 10,
          language: ngonNgu,
          specialization: val === "Tất cả" ? null : val,
          key_find: key_find,
          is_active: trangThai,
        })
      )
        .then(unwrapResult)
        .then((result) => {});
    }
    if (type === "keyFind") {
      let txt = val.target.value;

      if (timeout) clearTimeout(timeout);
      if (txt !== "") {
        timeout = setTimeout(async () => {
          if (txt.length >= 3) {
            dispatch(setKeyFind(txt));
            await dispatch(
              getBenhLyAdmin({
                page: 0,
                limit: 10,
                language: ngonNgu,
                specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
                key_find: txt,
                is_active: trangThai,
              })
            )
              .then(unwrapResult)
              .then((result) => {});
          } else {
          }
        }, 500);
      } else {
        dispatch(setKeyFind(txt));
        await dispatch(
          getBenhLyAdmin({
            page: 0,
            limit: 10,
            language: ngonNgu,
            specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
            key_find: null,
            is_active: trangThai,
          })
        )
          .then(unwrapResult)
          .then((result) => {});
      }
    }
  };

  const getArrChuyenKhoa = async () =>
  {
    dispatch(
      getArrChuyenKhoaStore({
        //@ts-ignore
        lang: ngonNgu,
      })
    )
      .then(unwrapResult)
      .then((result) =>
      {
        
        
        setArrChuyenKhoa(result);
      });
  }

  useEffect(() => {
    getArrChuyenKhoa()
  }, []);

  return (
    <div className="divSearchAdmin">
      <div className="divSearchNew">
        <div className="divSelect">
          <span className="title">Trạng thái</span>
          <Select
            style={{
              width: "150px",
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
            }}
            value={trangThai}
            // defaultValue={null}
            onChange={(val) => handleChange("trangThai", val)}
          >
            {arrStatus?.map((item) => (
              <Select.Option value={item.val}>{item.title}</Select.Option>
            ))}
          </Select>
        </div>

        <div className="divSelect">
          <span className="title">Ngôn ngữ</span>
          <Select
            style={{
              width: "150px",
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
            }}
            // defaultValue="vi"

            value={ngonNgu}
            onChange={(val) => handleChange("ngonNgu", val)}
          >
            {arrLang?.map((item) => (
              <Select.Option value={item.val}>{item.title}</Select.Option>
            ))}
          </Select>
        </div>

        <div className="divSelect">
          <span className="title">Chuyên khoa</span>
          <Select
            style={{
              width: "150px",
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
            }}
            value={chuyenKhoa}
            // defaultValue="Tất cả"
            onChange={(val) => handleChange("chuyenKhoa", val)}
          >
            <Select.Option value={null}>Tất cả</Select.Option>
            {arrChuyenKhoa?.map((item) => (
              <>
                <Select.Option value={item.id}>{item?.description}</Select.Option>
              </>
            ))}
          </Select>
        </div>

        

        <div>
          <Input
            style={{ width: "350px", paddingTop: "5px" }}
            prefix={<SearchOutlined />}
            placeholder="Nhập nội dung tìm kiếm"
            allowClear
            className="divInputSearch"
            onChange={(e) => handleChange("keyFind", e)}
          />
        </div>
      </div>

      <div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="btnAdd"
          onClick={() => navigate("/admin/add_post")}
        >
          Thêm mới
        </Button>
      </div>
    </div>
  );
};

export default Search;
