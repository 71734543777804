import axios from "utils/axios";

const ipLogin = process.env.REACT_APP_IP_LOGIN;


export async function getCurrentUser(payload: { token: string | null }) {
  const form = new FormData();
  // @ts-ignore
  form.append('token', payload.token)

  return axios.post(`${process.env.REACT_APP_IP_CHECK_TOKEN}`, form);
}

export async function login(payload: {
  username?: string | undefined;
  password?: string | undefined;
  grant_type: string | undefined;
}) {
  const form = new FormData();
  // @ts-ignore
  form.append('username', payload.username)
  // @ts-ignore
  form.append('password', payload.password)
  // @ts-ignore
  form.append('grant_type', payload.grant_type)
  return axios.post(`${ipLogin}`, form);
}

export async function refresh(payload: {
  refresh_token?: any;
  grant_type: string | undefined;
}) {
  const form = new FormData();
  // @ts-ignore
  form.append('refresh_token', payload.refresh_token)
  // @ts-ignore
  form.append('grant_type', payload.grant_type)
  return axios.post(`${ipLogin}`, form);
}
