import { useEffect, useState } from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "hooks";
import { getBenhLyStore, setKeySearch } from "stores/baiviet";
import { useNavigate } from "react-router-dom";
// import Sticky from "react-stickynode";

const App = (props: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const baiViet = useAppSelector((state) => state.baiviet);

  const [arr, setArr] = useState([]);

  // console.log(baiViet, "sun12");

  const dispatch = useAppDispatch();
  useEffect(() => {
    //@ts-ignore
    dispatch(
      getBenhLyStore({
        page: 0,
        limit: 6,
        isActive: true,
        //@ts-ignore
        token: localStorage.getItem("token") ?? null,
        language: i18n.language,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        setArr(result.data);
      });
    // eslint-disable-next-line
  }, [localStorage.getItem("lang")]);

  return (
    <div
      className={localStorage.getItem("token") ? "divTrending" : "divLichSu"}
    >
      <div>
        <img src="/chart.png" alt='chart'/>{" "}
        <span className="titleDivSideBar">{t("titleList")}</span>
        {arr?.map((item: any) => (
          <div
            style={{ paddingTop: "10px" }}
            className="divItem"
            onClick={() => {
              navigate(`../post/${item?._id}`, { replace: true });
              navigate(0);
              dispatch(setKeySearch(item?.original_post));
            }}
          >
            {/* <p style={{ marginBottom: "0px" }} className="desDivSideBar">
              {item.time}
            </p> */}
            <p style={{ marginBottom: "0px" }} className="contentDivSideBar">
              {item.original_post}
            </p>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
