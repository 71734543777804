import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCurrentUser } from "../services/users";
import {
  login as loginService,
  refresh as refreshService,
} from "services/users";
import { notification } from "antd";
import historyCustom from "utils/history";

export interface IInfoUser {
  access_token?: string;
  refresh_token?: string;
  user_type?: string;
  user_name?: string;
}

export interface IUser {
  data: IInfoUser;
  loading: boolean;
  loginLoading: boolean;
  isLogin: boolean;
}

export interface IPayloadActionGetCurrent {
  info: IInfoUser | {};
  cb: (current: IInfoUser) => void;
}

const initialState: IUser = {
  data: {},
  loginLoading: false,
  loading: false,
  isLogin: false,
};

export const refresh = createAsyncThunk("user/refresh", async () => {
  const payload = {
    refresh_token: localStorage.getItem("refresh_token"),
    grant_type: "refresh_token",
  };
  const response = await refreshService(payload);

  return response?.data ?? {};
});

export const login = createAsyncThunk(
  "user/login",
  async (payload: {
    username?: string | undefined;
    password?: string | undefined;
    grant_type: string | undefined;
  }) => {
    const response = await loginService(payload);

    return response?.data ?? {};
  }
);

export const getCurrent = createAsyncThunk(
  "user/getCurrent",
  async (payload: { token: string | null }) => {
    let response;
    if (payload.token) {
      response = await getCurrentUser(payload);
    }

    return response?.data ?? {};
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    logout: (state) => {
      // localStorage.clear();
      localStorage.removeItem("user_type");
      localStorage.removeItem("user_name");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("token");
      notification.success({
        message:
          localStorage.getItem("lang") === "vi"
            ? "Đăng xuất thành công"
            : "Log out successfully",
      });
      historyCustom.push("/");
      state.data = {};
    },
    setIsLogin: (state, { payload }) => {
      state.isLogin = payload;
    },

    setData: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.loginLoading = true;
    });
    builder.addCase(getCurrent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refresh.pending, (state) => {
      state.loading = true;
      state.loginLoading = true;
    });
    builder.addCase(
      login.fulfilled,
      (state, actions: PayloadAction<IInfoUser>) => {
        state.loading = false;
        state.loginLoading = false;
        state.isLogin = false;
        localStorage.setItem("token", actions.payload.access_token ?? "");
        localStorage.setItem(
          "refresh_token",
          actions.payload.refresh_token ?? ""
        );
        localStorage.setItem("user_type", actions.payload.user_type ?? "");
        notification.success({
          message:
            localStorage.getItem("lang") === "vi"
              ? "Đăng nhập thành công"
              : "Log in successfully",
        });
      }
    );

    builder.addCase(login.rejected, (state) => {
      state.loading = false;
      state.loginLoading = false;
    });

    builder.addCase(
      refresh.fulfilled,
      (state, actions: PayloadAction<IInfoUser>) => {
        state.loading = false;
        state.loginLoading = false;
        state.isLogin = false;
        localStorage.setItem("token", actions.payload.access_token ?? "");
        localStorage.setItem(
          "refresh_token",
          actions.payload.refresh_token ?? ""
        );
        localStorage.setItem("user_type", actions.payload.user_type ?? "");
      }
    );
    builder.addCase(
      getCurrent.fulfilled,
      (state, actions: PayloadAction<IInfoUser>) => {
        state.loading = false;
        state.data = actions.payload;
        localStorage.setItem("user_name", actions.payload.user_name ?? "");

        localStorage.setItem("user_type", actions.payload.user_type ?? "");
      }
    );
  },
});

export const { logout, setIsLogin } = userSlice.actions;

export default userSlice.reducer;
