/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
// import { Button, Modal } from 'antd';

import
  {
    DownOutlined,
    LogoutOutlined, SaveOutlined,
    SettingOutlined,
    UnorderedListOutlined
  } from "@ant-design/icons";
import
  {
    Button,
    Dropdown,
    Menu,
    Modal, Select, Space
  } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import FormLogin from "pages/User/FormLogin";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCurrent, logout, setIsLogin } from "stores/user";
import "./index.css";

const Nav = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const users = useAppSelector((store) => store.user);
  const navigate = useNavigate();

  // const { user_name, user_type } = users.data;

  const user_name = localStorage.getItem("user_name");
  const user_type = localStorage.getItem("user_type");

  useEffect(() => {
    //@ts-ignore
    dispatch(getCurrent({ token: localStorage.getItem("token") }));
  }, []);

  const handleCancel = () => {
    dispatch(setIsLogin(false));
  };

  const dangXuat = () => {
    dispatch(logout());
  };

  const handleChangeLang = (e: any) => {
    i18n.changeLanguage(e);
    localStorage.setItem("lang", e);
  };

  // console.log(window.location.pathname);

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <div>
        <div className="divnav">
          <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            <img src="/logo.png" alt="image" className="widthAnh" />
          </div>
          <div className="divbtnMobile">
            {!user_name && (
              <Button
                type="primary"
                className="btnLogin"
                onClick={() => dispatch(setIsLogin(true))}
              >
                {t("button.login")}
              </Button>
            )}

            {user_name && user_type !== "ADMIN" && (
              <>
                <Dropdown
                  overlay={
                    <Menu>
                      {/* <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/user"
                        >
                          <UserOutlined style={{ marginRight: "5px" }} />
                          Chi tiết hồ sơ
                        </a>
                      </Menu.Item> */}
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" href="/user/post_saved">
                          <SettingOutlined style={{ marginRight: "5px" }} />
                          {t("button.login")}
                        </a>
                      </Menu.Item>
                      {/* <Menu.Item key="2">
                        <a rel="noopener noreferrer" href="/user/history">
                          <MailOutlined style={{ marginRight: "5px" }} />
                          Lịch sử hoạt động
                        </a>
                      </Menu.Item> */}
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "5px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <img src="/user.png" style={{ marginRight: "10px" }} />
                </Dropdown>
              </>
            )}

            {user_name && user_type === "ADMIN" && (
              <>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/admin/manage_post"
                        >
                          <UnorderedListOutlined
                            style={{ marginRight: "10px" }}
                          />
                          {t("button.managePost")}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "10px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <img src="/user.png" style={{ marginRight: "10px" }} />
                </Dropdown>
              </>
            )}
          </div>

          {!user_name && (
            <div className="divbtn">
              <Select
                defaultValue={
                  !localStorage.getItem("lang")
                    ? "vi"
                    : localStorage.getItem("lang")
                }
                onChange={handleChangeLang}
              >
                <Select.Option value="vi">
                  <img src="/vi.png" height={"20px"} />
                </Select.Option>
                <Select.Option value="en">
                  <img src="/en.png" height={"20px"} />
                </Select.Option>
              </Select>
              <img
                src="/line.png"
                style={{ marginLeft: "20px", marginRight: "20px" }}
              />
              <Button
                type="primary"
                className="btnLogin"
                onClick={() => dispatch(setIsLogin(true))}
              >
                {t("button.login")}
              </Button>
            </div>
          )}
          {user_name && user_type !== "ADMIN" && (
            <>
              <div className="divbtn" style={{ cursor: "pointer" }}>
                <Select
                  defaultValue={
                    !localStorage.getItem("lang")
                      ? "vi"
                      : localStorage.getItem("lang")
                  }
                  onChange={handleChangeLang}
                >
                  <Select.Option value="vi">
                    <img src="/vi.png" height={"20px"} />
                  </Select.Option>
                  <Select.Option value="en">
                    <img src="/en.png" height={"20px"} />
                  </Select.Option>
                </Select>
                <img
                  src="/line.png"
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                />
                <img src="/user.png" style={{ marginRight: "10px" }} />

                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/user/post_saved"
                        >
                          <SaveOutlined style={{ marginRight: "10px" }} />
                          {t("button.postSaved")}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "10px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Space className="txtusername">
                    {user_name}
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Space>
                </Dropdown>
              </div>
            </>
          )}

          {user_name && user_type === "ADMIN" && (
            <>
              <div className="divbtn" style={{ cursor: "pointer" }}>
                {!window.location.pathname.includes("admin") && (
                  <Select
                    defaultValue={
                      !localStorage.getItem("lang")
                        ? "vi"
                        : localStorage.getItem("lang")
                    }
                    onChange={handleChangeLang}
                  >
                    <Select.Option value="vi">
                      <img src="/vi.png" height={"20px"} />
                    </Select.Option>
                    <Select.Option value="en">
                      <img src="/en.png" height={"20px"} />
                    </Select.Option>
                  </Select>
                )}

                <img
                  src="/line.png"
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                />
                <img src="/user.png" style={{ marginRight: "10px" }} />

                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/admin/manage_post"
                        >
                          <UnorderedListOutlined
                            style={{ marginRight: "10px" }}
                          />
                          {t("button.managePost")}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <a rel="noopener noreferrer" onClick={dangXuat}>
                          <LogoutOutlined style={{ marginRight: "10px" }} />
                          {t("button.logout")}
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Space className="txtusername">
                    {user_name}
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Space>
                </Dropdown>
              </div>
            </>
          )}

          <Modal
            title={t("button.login")}
            visible={users.isLogin}
            footer={null}
            onCancel={() => dispatch(setIsLogin(false))}
            // onCancel={handleCancel}
            className="modal"
          >
            <FormLogin cancel={handleCancel} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Nav;
