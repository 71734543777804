import React from "react";
import Nav from "../Nav/index";
import SiderMenu from "../SiderMenu";
import "./index.css";
import Footer from "components/FooterUser";

const LayoutUser = ({ children }) => {
  return (
    <>
      <div>
        <div style={{ background: "white" }}>
          <Nav />
        </div>

        <div className="containerLayout" style={{ marginTop: "32px" }}>
          <div className="divSiderMenu">
            <SiderMenu />
          </div>
          <div className="divChildren">{children}</div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default LayoutUser;
