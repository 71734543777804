import { MailOutlined, SaveOutlined } from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const data = [
  {
    label: "Bài viết đã lưu",
    icon: <SaveOutlined />,
    key: "/user/post_saved",
  },
  {
    label: "Lịch sử hoạt động",
    icon: <MailOutlined />,
    key: "/user/history",
  },
];

const dataEn = [
  {
    label: "Posts Save",
    icon: <SaveOutlined />,
    key: "/user/post_saved",
  },
  {
    label: "Operation history",
    icon: <MailOutlined />,
    key: "/user/history",
  },
];

let items: any[] = [];
let itemsEn: any[] = [];

data?.map((item) => {
  return items.push(getItem(item.label, item.key, item.icon));
});

dataEn?.map((item) => {
  return itemsEn.push(getItem(item.label, item.key, item.icon));
});

const SiderMenu: React.FC = () => {
  const { i18n } = useTranslation();
  // localStorage.setItem('path', '/user/infor');
  const navigate = useNavigate();
  const onClick: MenuProps["onClick"] = async (e) => {
    navigate(e.key);
  };

  return (
    <>
      <Menu
        style={{ padding: "20px", paddingTop: "10px" }}
        defaultSelectedKeys={[`${window.location.pathname}`]}
        defaultOpenKeys={[`${window.location.pathname}`]}
        onClick={onClick}
        mode="inline"
        items={i18n.language === "vi" ? items : itemsEn}
      />
    </>
  );
};

export default SiderMenu;
