import
  {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined
  } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Affix, Button, Result, Spin } from "antd";
import logo from "assets/images/logo.png";
import DetailPost from "components/BaiViet/DetailModal";
import CustomRouter from "components/CustomRouter";
import NavPost from "components/NavPost/indexPost";
import useWindowDimensions from "hooks/useWindowDimensions";
import DynamicPage from "pages/DynamicPage";
import Home from "pages/Home";
import LoginPage from "pages/LoginPage";
import ProtectedPage from "pages/ProtectedPage";
import PublicPage from "pages/PublicPage";
import HistorySearch from "pages/User/History";
import SavedPost from "pages/User/SavedPost";
import { useEffect, useLayoutEffect, useState } from "react";
import
  {
    // BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useNavigate
  } from "react-router-dom";
import constants from "utils/constants";
import historyCustom from "utils/history";
import { useAppDispatch, useAppSelector } from "../../hooks/index";
import { getCurrent } from "../../stores/user";
import AddPost from "./../../pages/Admin/QuanLyBaiViet/add";
import CopyPost from "./../../pages/Admin/QuanLyBaiViet/CopyPost";
import ManagePost from "./../../pages/Admin/QuanLyBaiViet/index";
import AdminLayout from "./../AdminLayout/index";
import LayoutUser from "./../BasicLayout/index";
import DropdownAction from "./DropdownAction";
import
  {
    ContentWrapper,
    DropdownUser,
    HeaderAction,
    HeaderTitle,
    HeaderUser,
    HeaderWrapper,
    LayoutWrapper
  } from "./index.style";
import Navbar from "./Navbar";

export const useReactPath = () => {
  const [path, setPath] = useState(window.location.pathname);

  const listenToPopstate = () => {
    const winPath = window.location.pathname;
    setPath(winPath);
  };
  useEffect(() => {
    window.addEventListener("pathnamechange", listenToPopstate);
    return () => {
      window.removeEventListener("pathnamechange", listenToPopstate);
    };
  }, []);
  return path;
};

const BasicLayout = () => {
  const user = useAppSelector((state) => state.user);
  const baiViet = useAppSelector((state) => state.baiviet);
  const { visibleMenu } = baiViet;
  return (
    // @ts-ignore
    <Spin spinning={user.loginLoading}>
      <CustomRouter history={historyCustom}>
        <Routes>
          <Route path={"/"} element={<Home />}></Route>
          <Route
            path={"/public"}
            element={
              <Layout>
                <PublicPage />
              </Layout>
            }
          ></Route>
          <Route path={"/login"} element={<LoginPage />}></Route>
          <Route
            path={"/post/:id"}
            element={
              <div
                style={{
                  background: "white !important",
                }}
              >
                {visibleMenu && <NavPost />}

                <div
                  style={{
                    background: "white",
                  }}
                >
                  <DetailPost />
                </div>
                {/* <Footer /> */}
              </div>
            }
          ></Route>
          <Route
            path={"/user/post_saved"}
            element={
              <PrivateRoute roles={["user"]}>
                <LayoutUser>
                  <SavedPost />
                </LayoutUser>
              </PrivateRoute>                                                                         
            }
          ></Route>
          <Route
            path={"/user/history"}
            element={
              <PrivateRoute roles={["user"]}>
                <LayoutUser>
                  <HistorySearch />
                </LayoutUser>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path={"/admin/manage_post"}
            element={
              <PrivateRoute roles={["admin"]}>
                <AdminLayout>
                  <ManagePost />
                </AdminLayout>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path={"/admin/add_post"}
            element={
              <PrivateRoute roles={["admin"]}>
                <AdminLayout>
                  <AddPost />
                </AdminLayout>
              </PrivateRoute>
            }
          ></Route>

          <Route
            path={"/admin/edit_post/:id"}
            element={
              <PrivateRoute roles={["admin"]}>
                <AdminLayout>
                  <AddPost />
                </AdminLayout>
              </PrivateRoute>
            }
          ></Route>

<Route
            path={"/admin/copy_post/:id"}
            element={
              <PrivateRoute roles={["admin"]}>
                <AdminLayout>
                  <CopyPost />
                </AdminLayout>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path={"/protected"}
            element={
              <PrivateRoute roles={["user"]}>
                <LayoutUser>
                  <ProtectedPage />
                </LayoutUser>
              </PrivateRoute>
            }
          ></Route>
          {/* Nếu cần các sub-page thì thêm path như này */}
          {/* Đồng thời cấu hình ở component Navbar.  */}
          {/* Làm tạm thế sau a sửa để dùng chung */}
          <Route
            path={"/pages/sub-page/sub-sub-page"}
            element={
              <PrivateRoute>
                <Layout>
                  <ProtectedPage />
                </Layout>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path={"/pages/sub-page-1"}
            element={
              <PrivateRoute>
                <Layout>
                  <ProtectedPage />
                </Layout>
              </PrivateRoute>
            }
          ></Route>
          {/* Nếu cần 1 path có id thì dùng như thế này. Nếu cần lấy id đó thì xem
          trong component DynamicPage */}
          {/* Thực tế thì path này sẽ k có trong file router mà sẽ được tạo ra khi muốn redirect đến
          Ví dự click vào link /pages/123abc. Nên k cần thiết thêm ở file router, việc thêm chỉ là ví dụ */}
          <Route
            path="/pages/:id"
            element={
              <PrivateRoute>
                <Layout>
                  <DynamicPage />
                </Layout>
              </PrivateRoute>
            }
          />
          {/* Nếu path không tồn tại sẽ tự động redirect về 404 */}
          <Route
            path="/404"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Xin lỗi, trang web không tồn tại"
                extra={<Button type="primary">Quay lại trang chủ</Button>}
              />
            }
          />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </CustomRouter>
    </Spin>
  );
};
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({
  children,
  roles,
}: {
  children: JSX.Element;
  roles?: Array<string>;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    //@ts-ignore
    dispatch(getCurrent({ token: localStorage.getItem("token") }))
      .then(unwrapResult)
      .then((result) => {
        // Kiểm tra đăng nhập, nếu result tồn tại -> Get đc thông tin user -> Đã đăng nhập
        // result undefined -> Chưa đăng nhập -> redirect login
        // Lệnh if (roles !== undefined) mang ý nghĩa nếu cần kiểm tra roles
        if (roles !== undefined) {
          let flag = true;
          // eslint-disable-next-line
          roles.map((role) => {
            if (!constants.access[role].includes(result?.user_type)) {
              flag = false;
            }
          });
          if (!flag) navigate("/404");
        }
      });
      // eslint-disable-next-line
  }, []);
  return children;
}

function Layout({ children }: { children: any }) {
  const { width: windowWidth } = useWindowDimensions();
  const [showNavbar, setShowNavbar] = useState(true);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    //@ts-ignore
    dispatch(getCurrent())
      .then(unwrapResult)
      .then((result) => {
        // Kiểm tra đăng nhập, nếu result tồn tại -> Get đc thông tin user -> Đã đăng nhập
        // result undefined -> Chưa đăng nhập -> redirect login
      });
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutWrapper>
      <Affix>
        <HeaderWrapper windowWidth={windowWidth} showNavbar={showNavbar}>
          <div>
            <HeaderTitle>
              <img src={logo} alt="logo-hexonia" />
              {showNavbar && <h1>Hexonia</h1>}
            </HeaderTitle>
          </div>
          <div>
            <HeaderAction>
              <div onClick={() => setShowNavbar(!showNavbar)}>
                {showNavbar ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              </div>
              <HeaderUser>
                <UserOutlined style={{ marginRight: 10 }} />{" "}
                {/* <b>{layoutCurrentUser?.name ?? ""}</b> */}
                <DropdownUser>
                  <DropdownAction />
                </DropdownUser>
              </HeaderUser>
            </HeaderAction>
          </div>
        </HeaderWrapper>
      </Affix>
      <ContentWrapper windowWidth={windowWidth} showNavbar={showNavbar}>
        <div>
          <Navbar />
        </div>
        <div
          style={{
            width: "100%",
            // backgroundColor: "blue",
            height: "100vh",
          }}
        >
          {children}
        </div>
      </ContentWrapper>
    </LayoutWrapper>
  );
}

export default BasicLayout;
