import defaultConfig from "config/defaultConfig";
import styled from "styled-components";

export const NavbarWrapper = styled.ul`
  margin: 0;
  width: 100%;

  & li {
    list-style-type: none;
    font-size: 17px;
    color: white;
    cursor: pointer;
  }

  & li div {
    padding: 10px 0px;
    padding-left: 20px;
    padding-right: 10px;
  }

  & li a {
    color: white;
    padding: 10px 0px;
    padding-left: 20px;
    display: block;
  }

  & ul li {
    background-color: ${defaultConfig.colorBackgroundSubNavbar};
  }

  & ul li div {
    padding-left: 20px;
  }
`;
