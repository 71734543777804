import defaultConfig from "config/defaultConfig";
import styled from "styled-components";

const widthNavbar = (showNavbar) => {
  return !showNavbar
    ? `${defaultConfig.logoNavbarWidth + 25}px`
    : typeof defaultConfig.navbarWidth === "number"
    ? `${defaultConfig.navbarWidth}px`
    : defaultConfig.navbarWidth;
};

const widthContent = (showNavbar, windowWidth) => {
  return !showNavbar
    ? `${windowWidth - defaultConfig.logoNavbarWidth}px`
    : typeof defaultConfig.navbarWidth === "number"
    ? `${windowWidth - defaultConfig?.navbarWidth}px`
    : defaultConfig?.navbarWidth;
};

export const LayoutWrapper = styled.div`
  width: 100%;
`;

export const Layout = styled.div`
  width: 100vw;
  display: flex;
  & > div:nth-child(1) {
    width: ${(props) => widthNavbar(props.showNavbar)};
  }
  & > div:last-child {
    width: ${(props) => widthContent(props.showNavbar, props.windowWidth)};
  }
`;

export const HeaderWrapper = styled(Layout)`
  box-shadow: 0 2px 8px #f0f1f2;
  background-color: ${defaultConfig.mainColorBackground};
  color: white;
`;

export const ContentWrapper = styled(Layout)`
  & > div:nth-child(1) {
    position: fixed;
  }

  & > div:nth-child(2) {
    margin-left: ${(props) => widthNavbar(props.showNavbar)} !important;
  }
  & > div > ul {
    background-color: ${defaultConfig.mainColorBackground};
    height: 100vh;
    width: ${(props) => widthNavbar(props.showNavbar)} !important;
  }
`;

export const HeaderTitle = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  & img {
    width: ${defaultConfig.logoNavbarWidth}px;
    height: ${defaultConfig.logoNavbarWidth}px;
    margin-right: 15px;
  }
  & h1 {
    color: white;
    font-weight: bold;
    margin: 0px;
  }
`;

export const HeaderAction = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  position: relative;
  & svg {
    font-size: 20px;
  }
`;

export const DropdownUser = styled.div`
  position: absolute;
  display: none;
  width: 180px;
  background-color: white;
  position: absolute;
  top: 98%;
  right: 0px;
  z-index: 1;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  & div {
    padding: 10px;
    padding-bottom: 0px;
  }
  & div p {
    color: black;
    margin: 0;
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  & div p:nth-child(1) {
    padding-top: 0px;
  }
  & div p:last-child {
    border-bottom: none;
  }
  & div p svg {
    margin-right: 10px;
  }
`;

export const HeaderUser = styled.div`
  position: absolute;
  right: 0;
  top: 2%;
  height: 100%;
  width: 10%;
  cursor: pointer;
  display: flex;
  align-items: center;
  & b {
    font-size: 18px;
  }

  &:hover ${DropdownUser} {
    display: block;
  }
`;
