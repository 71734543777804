import Search from "./search";
import TablePost from "./table";

const Post = () => {
  return (
    <div style={{ background: "#F5F5F5" }}>
      <Search />
      <TablePost />
    </div>
  );
};

export default Post;
