import Nav from "components/Nav";

import "./index.css";

const layout = ({ children }) => {
  // if (history.location.pathname === "/admin") {
  //   history.push("/admin/manage-post");
  // }

  return (
    <>
      <div>
        <div style={{ background: "white" }}>
          <Nav />
        </div>

        <div className="container" style={{ marginTop: "32px" }}>
          <div className="divChildren">{children}</div>
        </div>
      </div>
      {/* <Nav />
      <div className="container" style={{ marginTop: "32px" }}>
        <div>{children}</div>
      </div> */}
    </>
  );
};
export default layout;
