import React from "react";
import "./index.css";
import packageJson from "../../../package.json";

const Footer = () => {
  return (
    <div className="div">
      <div className="divCon">
        <a
          style={{ marginBottom: 0, color: "white" }}
          href="https://medihome.com.vn/"
          target="_blank"
          rel="noreferrer"
        >
          © Copyright - Medihome (v{packageJson.version})
        </a>
      </div>
    </div>
  );
};

export default Footer;
