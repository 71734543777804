/* eslint-disable react-hooks/rules-of-hooks */
import axios from "utils/axios";

const ip = process.env.REACT_APP_IP;

const ipMedihome = process.env.REACT_APP_IP_MEDIHOME;

export async function getPostLink(payload: any) {
  return axios.post(
    `${ip}/find_post_link`, payload
  );
}

export async function getBenhLyHome(payload: any) {
  return axios.post(
    `${ip}/list_post?page=${payload.page}&limit=${payload.limit}&is_active=${payload.isActive}&language=${localStorage.getItem('lang')}`,
    payload
  );
}

export async function getBenhLy(payload: any) {
  return axios.post(
    `${ip}/Search_and_sort_post`, null, { params: payload }
  );
}

export async function searchBenhLy(payload: any) {
  
  if (payload.token) {
    return axios.post(
      `${ip}/find_list_post?token=${payload.token}&&key_find=${payload.key_find}&&page=${payload.page}&&limit=${payload.limit}`,
      payload
    );
  } else {
    return axios.post(
      `${ip}/find_list_post?key_find=${payload.key_find}&&page=${payload.page}&&limit=${payload.limit}`,
      payload
    );
  }
}

export async function searchBenhLyHeader(payload: any) {
  
  return axios.post(
    `${ip}/find_list_post_in_header`, null, { params: payload }
  );
}

export async function getPostByIdLoading(payload: any) {
  return axios.post(`${ip}/get_post_by_name`, payload);
}

export async function getPostById(payload: any) {
  return axios.post(`${ip}/get_post_by_name`, payload);
}

export async function postBenhLy(payload: any) {
  return axios.post(`${ip}/create_post?token=${payload.token}`, payload);
}

export async function editBenhLy(payload: any) {
  const id = payload?._id ?? "";

  return axios.post(`${ip}/edit_post/${id}?token=${payload.token}`, payload);
}

export async function getEdittBenhLy(payload: { post_id: string }) {
  return axios.post(`${ip}/get_post_edit`, payload);
}

export async function savePost(payload: {
  post_id: string;
  token: string | undefined;
}) {
  return axios.post(`${ip}/save_post`, payload);
}

export async function getListSavedPost(payload: { token: string }) {
  // return axios.get(`${ip}/list_post_by_user`, {params: payload});
  return axios.post(`${ip}/list_save_post_by_user`, payload);
}

export async function delSavedPost(payload: {
  token: string | undefined;
  post_id: string;
}) {
  return axios.delete(`${ip}/delete_save_post`, { params: payload });
}

export async function delPost(payload: {
  token: string | undefined;
  post_id: string;
}) {
  return axios.delete(
    `${ip}/delete_post/${payload.post_id}?token=${payload.token}`
  );
}

export async function getUserHistory(payload: { token: string | undefined }) {
  return axios.get(`${ip}/user_history?token=${payload.token}`);
}

export async function getHistorySearch(payload: { token: string | undefined }) {
  // @ts-ignore
  return axios.get(`${ip}/user_history_key_find`, null, { parmas: payload });
}

export async function delHistorySearch(payload: { token: string; id: string }) {
  return axios.delete(`${ip}/delete_user_history/${payload.id}`, {
    params: { token: payload.token },
  });
}

export async function getSavedPost(payload: {
  page: any;
  token: string | undefined;
  limit: any;
}) {
  const newPay = {
    token: "",
    sick: ["sick"],
    authorities: ["ROLE_USER"],
    post_id: "",
    key_find: "",
  };
  return axios.post(
    `${ip}/find_post_save?token=${payload.token}&page=${payload.page}&limit=${payload.limit}`,
    newPay
  );
}


export async function createHistoryKeyfind(payload: { data: string }) {
  // return axios.get(`${ip}/list_post_by_user`, {params: payload});
  return axios.post(`${ip}/create_history_keyfind`, payload);
}

export async function getPostHistory() {
  // return axios.get(`${ip}/list_post_by_user`, {params: payload});
  return axios.get(`${ip}/post_history`);
}

export async function getArrChuyenKhoa(payload: any)
{
  
  return axios.get(`${ipMedihome}/doctor/specialization/get-list`, { params: payload });
}
