// /* eslint-disable jsx-a11y/alt-text */
// import CardItem from "components/BaiViet/CardItem";
// import { Col, Divider, Pagination, Row } from "antd";

// import "./index.css";
// import { unwrapResult } from "@reduxjs/toolkit";
// import { useAppDispatch, useAppSelector } from "hooks";
// import { getSavedPostStore } from "stores/baiviet";
// import { useEffect } from "react";

// const SavedPost = () => {
//   const baiViet = useAppSelector((state) => state.baiviet);
//   const { arrPostSaved, totalPostSave } = baiViet;
//   const dispatch = useAppDispatch();
//   useEffect(() => {
//     //@ts-ignore
//     dispatch(
//       getSavedPostStore({
//         //@ts-ignore
//         token: localStorage.getItem("token") ?? null,
//         page: 0,
//         limit: 12,
//       })
//     )
//       .then(unwrapResult)
//       .then((result) => {});
//   }, []);

//   const handlePaging = (page: any, pageSize: any) => {
//     dispatch(
//       getSavedPostStore({
//         //@ts-ignore
//         token: localStorage.getItem("token") ?? null,
//         page: page - 1,
//         limit: 12,
//       })
//     )
//       .then(unwrapResult)
//       .then((result) => {});
//   };

//   return (
//     <div className="divTo">
//       <div className="divTitle">Bài viết đã lưu</div>
//       <div className="divDes">Quản lý danh sách bài viết đã lưu</div>{" "}
//       <Divider />
//       <Row gutter={[24, 10]}>
//         {arrPostSaved?.map((item: any) => {
//           return (
//             <Col xs={24} sm={12} md={8}>
//               <CardItem data={item} />
//             </Col>
//           );
//         })}
//       </Row>
//       {arrPostSaved?.length === 0 && (
//         //@ts-ignore
//         <center>
//           <img src="/empty.png" />
//           {/* @ts-ignore */}
//         </center>
//       )}
//       {totalPostSave > 12 && (
//         <div style={{ float: "right", marginTop: "30px" }}>
//           <Pagination
//             defaultCurrent={1}
//             total={totalPostSave}
//             pageSize={12}
//             onChange={handlePaging}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default SavedPost;

import React from "react";

import SaveList from "components/BaiViet/SaveList";

const SavedPost = () => {
  return <SaveList />;
};

export default SavedPost;
