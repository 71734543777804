/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { unwrapResult } from "@reduxjs/toolkit";
import { Divider } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPostHistoryStore } from "stores/baiviet";
// import Sticky from "react-stickynode";

const App = (props: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const baiViet = useAppSelector((state) => state.baiviet);

  const [arr, setArr] = useState([]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    //@ts-ignore
    dispatch(getPostHistoryStore())
      .then(unwrapResult)
      .then((result) => {
        setArr(result);
      });
  }, [window.location.pathname]);

  return (
    <div className="divLichSu">
      <div>
        <img src="/clock.png" />{" "}
        <span className="titleDivSideBar">{t("titleLichSu")}</span>
        {arr?.map((item: any) => (
          <div
            style={{ paddingTop: "10px", cursor: "pointer" }}
            className="divItem"
            onClick={() => {
              navigate(`../post/${item?.note}`, { replace: true });
              navigate(0);
              // dispatch(setKeySearch(item?.original_post));
            }}
          >
            <p style={{ marginBottom: "0px" }} className="desDivSideBar">
              {moment(item.created_at).format("HH:mm:ss DD/MM/yyyy")}
            </p>
            <p style={{ marginBottom: "0px" }} className="contentDivSideBar">
              {item.data}
            </p>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
