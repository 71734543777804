import { PieChartOutlined } from "@ant-design/icons";
import { Divider, MenuProps, Tooltip } from "antd";
import { Menu, Affix } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Sticky from "react-stickynode";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const App = (props: any) => {
  const { t } = useTranslation();
  const [dataItem, setDataItem] = useState<any>([]);
  const { collapsed, setCollapsed, visibleMenu } = props;
  useEffect(() => {
    let items: any[] = [];

    // props?.data?.map((item: any, index: any) => {
    //
    //   items.push({
    //     label: item.name,
    //     key: item.key,
    //   });
    // });

    setDataItem(props?.data);
  }, [props.data]);

  const [stateClass, setStateClass] = useState(undefined);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const clickMenu = (key: any) => {
    const element = window.document.getElementById(key)!;

    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    // <Sticky top={130} bottomBoundary="#content">
    <>
      <Affix offsetTop={!visibleMenu ? 30 : 110}>
        <div
          style={{
            width: !collapsed ? "200px" : "60px",
            borderRight: !collapsed ? "1px solid #d9d9d9" : "none",
          }}
          className="divMenu"
        >
          <div
            onClick={toggleCollapsed}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            {!collapsed && (
              <span
                style={{ color: "#5A6B81", fontWeight: 500, fontSize: "14px" }}
              >
                {t("titleMucLuc")}
              </span>
            )}

            <svg
              style={{
                position: !collapsed ? "absolute" : "initial",
                marginLeft: !collapsed ? "160px" : "0px",
              }}
              xmlns="http://www.w3.org/2000/svg"
              width={36}
              height={36}
              viewBox="0 0 36 36"
              fill="none"
            >
              <g filter="url(#filter0_d_553_13659)">
                <rect
                  x={4}
                  y={2}
                  width={28}
                  height={28}
                  rx={14}
                  fill="white"
                  shapeRendering="crispEdges"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.5422 12.4352C21.3407 12.2464 21.0243 12.2566 20.8354 12.458L17.8354 15.658C17.6551 15.8503 17.6551 16.1496 17.8354 16.342L20.8354 19.542C21.0243 19.7434 21.3407 19.7536 21.5422 19.5648C21.7436 19.3759 21.7538 19.0595 21.565 18.858L18.8856 16L21.565 13.142C21.7538 12.9405 21.7436 12.6241 21.5422 12.4352Z"
                  fill="#5A6B81"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.1423 12.4352C17.9408 12.2464 17.6244 12.2566 17.4355 12.458L14.4355 15.658C14.2552 15.8503 14.2552 16.1496 14.4355 16.342L17.4355 19.542C17.6244 19.7434 17.9408 19.7536 18.1423 19.5648C18.3437 19.3759 18.3539 19.0595 18.1651 18.858L15.4857 16L18.1651 13.142C18.3539 12.9405 18.3437 12.6241 18.1423 12.4352Z"
                  fill="#5A6B81"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_553_13659"
                  x={0}
                  y={0}
                  width={36}
                  height={36}
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy={2} />
                  <feGaussianBlur stdDeviation={2} />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.870677 0 0 0 0 0.884618 0 0 0 0 0.9125 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_553_13659"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_553_13659"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          {!collapsed &&
            props.data?.map((item: any, index: any) => (
              <div
                style={{ cursor: "pointer" }}
                className="divItem"
                onClick={() => {
                  clickMenu(item.key);
                  // setStateClass(
                  //   index === item.key ? "menuActive" : "menuNotActive"
                  // );
                  setStateClass(item.key);
                }}
                id={`divItem${index}`}
              >
                <p
                  style={{ marginBottom: "10px" }}
                  className={
                    stateClass === index ? "menuActive" : "menuNotActive"
                  }
                >
                  {item.label}
                </p>
              </div>
            ))}
        </div>
      </Affix>
    </>
  );
};

export default App;
