/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/rules-of-hooks */
import
  {
    ExclamationCircleOutlined, PlusOutlined
  } from "@ant-design/icons";
import
  {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input, Modal,
    notification,
    Row,
    Select,
    Spin
  } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import TinyEditor from "./../../../components/TinyEditor/Tiny";
import rules from "./../../../utils/rules";
// import { history, useModel } from 'umi';
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import
  {
    editBenhLyStore, getBenhLyAdmin, getEdittBenhLyStore, getPostLinkStore, postBenhLyStore, getArrChuyenKhoaStore
  } from "stores/baiviet";
import data from "./../../../utils/data";
import "./index.css";
import axios from "utils/axios";

const add = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const baiViet = useAppSelector((state) => state.baiviet);
  const { loading, page, record } = baiViet;
  // console.log(baiViet, "baiViet"); // ddaya

  const edit = document.location.pathname.includes("/admin/edit_post");

  const [active, setActive] = useState(false);
  const [visibleHuy, setVisibleHuy] = useState(false);

  const [stateBtn, setStateBtn] = useState(false);
  const [hideEditor, setHideEditor] = useState({});

  const [arrPost, setArrPost] = useState<any>([]);
  const { trangThai, ngonNgu, chuyenKhoa, key_find } = baiViet;

  const [arrChuyenKhoa, setArrChuyenKhoa] = useState<any>([]);
  const [chuyenKhoaSelected, setChuyenKhoaSelected] = useState<any>({});

  useEffect(() => {
    getData();
    getArrChuyenKhoa()

    // functionCheck()
  }, []);

  // const functionCheck = () =>
  // {
  //   const res = axios.get(`${process.env.REACT_APP_IP_MEDIHOME}/doctor/specialization/8034`,);
  //   debugger
  // }

  const getData = async () => {
    form.setFieldsValue(null);
    if (edit) {
      dispatch(
        getEdittBenhLyStore({
          //@ts-ignore
          post_id: id,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          let newRe = { ...result };
          newRe.point = result?.point?.total ?? 0;
          newRe.language = result?.language ?? "vi";
          newRe.post_link = result?.post_link?.original_post;
          newRe.specialization = result?.specialization?.id
setChuyenKhoaSelected(result?.specialization)
          form.setFieldsValue(newRe);

          dispatch(
            getPostLinkStore({
              //@ts-ignore
              language: result?.language === "vi" ? "en" : "vi",
            })
          )
            .then(unwrapResult)
            .then((result) => {
              setArrPost(result.data);
            });
        });
    } else {
      //@ts-ignore
      const newVal = {
        language: ngonNgu === "vi" ? "vi" : "en",
      };
      form.setFieldsValue(newVal);
      dispatch(
        getPostLinkStore({
          //@ts-ignore
          language: ngonNgu === "vi" ? "en" : "vi",
        })
      )
        .then(unwrapResult)
        .then((result) => {
          setArrPost(result.data);
        });
    }
  };


  const getArrChuyenKhoa = async () =>
  {
    dispatch(
      getArrChuyenKhoaStore({
        //@ts-ignore
        lang: ngonNgu,
      })
    )
      .then(unwrapResult)
      .then((result) =>
      {
        setArrChuyenKhoa(result);
      });
  } 
  const tailLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayoutForm = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const arrStatus = [
    {
      title: "Đăng bài",
      val: true,
    },
    {
      title: "Tạm ẩn",
      val: false,
    },
  ];

  const arrLang = [`Tiếng Việt`, `Tiếng Anh`];

  const handleFinish = async (values: any) => {
    setStateBtn(true);
    let data: { name: string; level: string; content: any }[] = [];
    values?.data?.map(
      (item: { name: string; level: string; content: { text: any } }) => {
        data.push({
          name: item?.name,
          level: item?.level,
          content: item?.content?.text ? item?.content?.text : item?.content,
        });
      }
    );

    values.data = data;
    const a = parseInt(values.point ? values.point : 0);

    delete values.point;
    values.point = {
      fake: a,
      total: a,
    };

    values.specialization = chuyenKhoaSelected

    if (!edit)
    {
      dispatch(
        postBenhLyStore(
          //@ts-ignore
          { ...values, token: localStorage.getItem("token") }
        )
      )
        .then(unwrapResult)
        .then(async (result) => {
          await dispatch(
            getBenhLyAdmin({
              //@ts-ignore
              page: page,
              limit: 10,
              language: ngonNgu,
              specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
              key_find: key_find,
              is_active: trangThai,
            })
          )
            .then(unwrapResult)
            .then((result) => {});
          notification.success({
            message: `${t("message.sucessPost")}`,
          });
          navigate("/admin/manage_post");
        });
    } else {
      if (values.post_link === record?.post_link?.original_post)
      {
       
        values.post_link = record?.post_link?._id;
      }

      dispatch(
        editBenhLyStore(
          //@ts-ignore
          {
            ...values,
            token: localStorage.getItem("token"),
            _id: record?._id,
            edit: "edit_post ",
          }
        )
      )
        .then(unwrapResult)
        .then(async (result) => {
          notification.success({
            message: `${t("message.sucessEdit")}`,
          });
          await dispatch(
            getBenhLyAdmin({
              //@ts-ignore
              page: page,
              limit: 10,
              language: ngonNgu,
              specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
              key_find: key_find,
              is_active: trangThai,
            })
          )
            .then(unwrapResult)
            .then((result) => {});

          navigate("/admin/manage_post");
        });
    }
  };

  const checkNotUndefined = (obj: any) => {
    let flag = true;

    Object.keys(obj).forEach((key) => {
      /* @ts-ignore */

      if (key === "original_post") {
        if (obj[key] === undefined) {
          flag = false;
        }
        return;
      }
    });

    return flag;
  };

  const handleHuy = () => {
    if (visibleHuy) {
      Modal.confirm({
        title: "Xác nhận hủy",
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có chắc chắn muốn quay lại, dữ liệu chưa lưu?",
        okText: "Đồng ý",
        okType: "danger",
        okButtonProps: {},
        cancelText: "Hủy",
        onOk() {
          navigate("/admin/manage_post");
        },
      });
    } else {
      navigate("/admin/manage_post");
    }
  };

  const handleChangeLang = (val: any) => {
    dispatch(
      getPostLinkStore({
        //@ts-ignore
        language: val === "vi" ? "en" : "vi",
      })
    )
      .then(unwrapResult)
      .then((result) => {
        setArrPost(result.data);
      });
    
      dispatch(
        getArrChuyenKhoaStore({
          //@ts-ignore
          lang: val,
        })
      )
        .then(unwrapResult)
        .then((result) =>
        {
          
          
          setArrChuyenKhoa(result);
        });
  };

  return (
    <Spin spinning={edit ? !!loading : false}>
      <div style={{ background: "#F5F5F5" }}>
        <Form
          onFinish={handleFinish}
          form={form}
          onValuesChange={(valueChanged, allValue) => {
            setVisibleHuy(true);
            if (checkNotUndefined(allValue)) {
              setActive(true);
            }
          }}
          scrollToFirstError
        >
          <Row gutter={[12, 12]}>
            <Col md={18}>
              <Card style={{ borderRadius: "8px" }}>
                <Form.Item
                  {...tailLayoutForm}
                  style={{ marginBottom: "4px" }}
                  label="Tiêu đề"
                  className="label"
                  rules={[...rules.required, ...rules.length(255)]}
                  name="original_post"
                >
                  <Input placeholder="Nhập tiêu đề " />
                </Form.Item>

                <Form.Item
                  {...tailLayoutForm}
                  style={{ marginBottom: "4px" }}
                  label="Mô tả"
                  className="label"
                  rules={[...rules.length(500)]}
                  name="summary"
                >
                  <Input.TextArea rows={3} placeholder="Nhập mô tả" />
                </Form.Item>

                <Form.Item
                  {...tailLayoutForm}
                  style={{ marginBottom: "4px" }}
                  label="Tags"
                  className="label"
                  rules={[...rules.length(255)]}
                  name="translation_post"
                >
                  <Input placeholder="Nhập tags" />
                </Form.Item>

                {/* <Form.Item {...tailLayoutForm} label="Upload PDF" className={styles.label}>
                <UploadFile />
              </Form.Item> */}
                <Form.List name="data">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <>
                            <div style={{ marginTop: "20px" }}>
                              <div style={{ display: "flex" }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  rules={[
                                    { required: true, message: "Tiêu đề khối" },
                                  ]}
                                  style={{
                                    width: "100%",
                                    marginRight: "8px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Input placeholder="Nhập tiêu đề khối" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "level"]}
                                  style={{
                                    width: "100%",
                                    marginRight: "8px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Select
                                    placeholder="Chọn quyền truy cập"
                                    mode="multiple"
                                    style={{
                                      border: "1px solid #d9d9d9",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    {data.arrQuyen?.map((item) => (
                                      <Select.Option value={item.name}>
                                        {item.comment}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>

                                <Button
                                  onClick={() => {
                                    remove(name);
                                  }}
                                  className="btnDelBlock"
                                  style={{ marginRight: "8px" }}
                                  icon={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={20}
                                      height={20}
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                                        stroke="#EB5757"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.08325 4.14163L7.26659 3.04996C7.39992 2.25829 7.49992 1.66663 8.90825 1.66663H11.0916C12.4999 1.66663 12.6083 2.29163 12.7333 3.05829L12.9166 4.14163"
                                        stroke="#EB5757"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M15.7084 7.6167L15.1667 16.0084C15.0751 17.3167 15.0001 18.3334 12.6751 18.3334H7.32508C5.00008 18.3334 4.92508 17.3167 4.83341 16.0084L4.29175 7.6167"
                                        stroke="#EB5757"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M8.6084 13.75H11.3834"
                                        stroke="#EB5757"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.91675 10.4166H12.0834"
                                        stroke="#EB5757"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  }
                                ></Button>

                                <Button
                                  onClick={() => {
                                    setHideEditor({
                                      ...hideEditor,
                                      [`${key}`]: !hideEditor[key],
                                    });
                                  }}
                                  className="btnDelBlock"
                                  icon={
                                    hideEditor?.[key] ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <rect
                                          width={20}
                                          height={20}
                                          rx={8}
                                          fill="#F1F4FA"
                                        />
                                        <path
                                          d="M13.96 11.5249L10.7 8.26495C10.315 7.87995 9.68504 7.87995 9.30004 8.26495L6.04004 11.5249"
                                          stroke="#5A6B81"
                                          strokeWidth="1.5"
                                          strokeMiterlimit={10}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <rect
                                          width={20}
                                          height={20}
                                          rx={8}
                                          fill="#F1F4FA"
                                        />
                                        <path
                                          d="M13.96 8.47493L10.7 11.7349C10.315 12.1199 9.68504 12.1199 9.30004 11.7349L6.04004 8.47493"
                                          stroke="#5A6B81"
                                          strokeWidth="1.5"
                                          strokeMiterlimit={10}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    )
                                  }
                                ></Button>
                              </div>
                              {hideEditor?.[key] && (
                                <Form.Item
                                  style={{ marginBottom: "4px" }}
                                  name={[name, "content"]}
                                >
                                  {/* @ts-ignore */}
                                  <TinyEditor height={350} />
                                </Form.Item>
                              )}
                            </div>
                            <Divider />
                          </>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            setHideEditor({
                              ...hideEditor,
                              [`${fields.length}`]: false,
                            });
                            add();
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Thêm khối nội dung
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                style={{ borderRadius: "8px", padding: 0 }}
                title="Thông tin"
              >
                <div>
                  <Form.Item
                    labelAlign="left"
                    {...tailLayout}
                    style={{ marginBottom: "4px" }}
                    label="Ngôn ngữ"
                    className="label"
                    name="language"
                    colon={false}
                    // initialValue="vi"
                    // rules={[...rules.required]}
                  >
                    <Select
                      placeholder="Chọn ngôn ngữ"
                      style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "8px",
                      }}
                      onChange={handleChangeLang}
                      // defaultValue="vi"
                    >
                      {arrLang?.map((item) => (
                        <Select.Option
                          value={item === "Tiếng Việt" ? "vi" : "en"}
                        >
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    labelAlign="left"
                    {...tailLayout}
                    style={{ marginBottom: "4px" }}
                    label="Trạng thái"
                    className="label"
                    name="is_active"
                    colon={false}
                    // rules={[...rules.required]}
                  >
                    <Select
                      placeholder="Chọn trạng thái"
                      style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "8px",
                      }}
                    >
                      {arrStatus?.map((item) => (
                        <Select.Option value={item.val}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    labelAlign="left"
                    {...tailLayout}
                    style={{ marginBottom: "4px" }}
                    label="Chuyên khoa"
                    className="label"
                    colon={false}
                    name="specialization"
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Chọn chuyên khoa"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA!.children as unknown as string)
                          .toLowerCase()
                          .localeCompare(
                            (
                              optionB!.children as unknown as string
                            ).toLowerCase()
                          )
                      }
                      style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "8px",
                      }}
                      onChange={
                        (val: any, obj: any) =>
                        {
                          setChuyenKhoaSelected(obj?.obj)
                        }
                      }
                    >
                      {arrChuyenKhoa?.map((item) => (
                        <Select.Option value={item.id} obj={item}>
                          {item?.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    labelAlign="left"
                    {...tailLayout}
                    style={{ marginBottom: "4px" }}
                    label="Bài viết liên kết"
                    className="label"
                    colon={false}
                    name="post_link"
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Chọn bài viết liên kết"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA!.children as unknown as string)
                          .toLowerCase()
                          .localeCompare(
                            (
                              optionB!.children as unknown as string
                            ).toLowerCase()
                          )
                      }
                      style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "8px",
                      }}
                    >
                      {arrPost?.map((item: any) => (
                        <Select.Option value={item._id}>
                          {item.original_post}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {edit && (
                    <Form.Item
                      {...tailLayout}
                      style={{ marginBottom: "4px" }}
                      label="Ngày đăng"
                      className="label"
                      colon={false}
                      labelAlign="left"
                    >
                      {/* @ts-ignore */}
                      {moment(record?.created_at).format("HH:mm DD/MM/YYYY")}
                    </Form.Item>
                  )}

                  <Form.Item
                    {...tailLayout}
                    style={{ marginBottom: "4px" }}
                    labelAlign="left"
                    label="Điểm"
                    className="label"
                    colon={false}
                    name="point"
                    rules={[...rules.number(1000)]}
                  >
                    <Input placeholder="Nhập điểm " />
                  </Form.Item>
                </div>
              </Card>
              <div style={{ marginTop: "10px", display: "flex" }}>
                <Button className="btnHuy" onClick={handleHuy}>
                  Hủy
                </Button>
                <Button
                  className="btnDangBai"
                  htmlType="submit"
                  disabled={!active}
                  loading={stateBtn}
                >
                  {edit ? `Cập nhật` : `Đăng bài`}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default add;
