import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Form, Input } from "antd";
import "components/Nav/index.css";
import { useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import { getCurrent, login } from "stores/user";
import historyCustom from "utils/history";

const FormLogin = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      // @ts-ignore
      onFinish={async (values) => {
        // @ts-ignore
        values.grant_type = "password";
        await dispatch(login(values))
          .then(unwrapResult)
          .then((result) => {
            dispatch(getCurrent({ token: result?.access_token }));
            window.location.reload();
            // historyCustom.push("/");
          });
      }}
      autoComplete="off"
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input className="inputFormLogin" prefix={<UserOutlined />} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password className="inputFormLogin" prefix={<LockOutlined />} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
        <Button
          type="primary"
          className="btnLogin"
          htmlType="submit"
          // onClick={() => setVisibleLogin(true)}
        >
          {t("button.login")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormLogin;
