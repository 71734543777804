import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "stores/rootReducer/rootReducer.d";

const store = configureStore({
  reducer: rootReducer,
});

// if auto import reducer, comment this. Because declare manual type so don't need this
export type RootState = ReturnType<typeof store.getState>; // A global type to access reducers types

export type AppDispatch = typeof store.dispatch; // Type to access dispatch

export default store;
