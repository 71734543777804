import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import rootReducer from "stores";
import "./App.css";
import BasicAuthenticationLayout from "./components/BasicAuthenticationLayout";

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language) {
      localStorage.setItem("lang", i18n.language);
    } else {
      localStorage.setItem("lang", "vi");
    }
    // eslint-disable-next-line
  }, []);

  return (
    // @ts-ignore
    <Provider store={rootReducer}>
      <BasicAuthenticationLayout />
    </Provider>
  );
}

export default App;
