import { UserOutlined } from "@ant-design/icons";
import DynamicPage from "pages/DynamicPage";
import Home from "pages/Home";
import ProtectedPage from "pages/ProtectedPage";
import PublicPage from "../pages/PublicPage/index";
import { IRoute } from "./route.d";

const routes: Array<IRoute> = [
  {
    title: "home",
    component: <Home />,
    path: "/",
    hideInMenu: true,
  },
  {
    title: "Public pages",
    component: <PublicPage />,
    icon: <UserOutlined />,
    path: "/public",
    layout: true,
  },
  {
    title: "Proteted pages",
    component: <ProtectedPage />,
    path: "/protected",
    authentication: true,
  },
  {
    title: "Pages",
    path: "/pages",
    authentication: true,
    routes: [
      {
        title: "Sub-page",
        path: "/sub-page",
        routes: [
          {
            title: "Sub-sub-page",
            path: "/sub-sub-page",
            component: <ProtectedPage />,
          },
        ],
      },
      {
        title: "Sub-page-1",
        path: "/sub-page-1",
        component: <ProtectedPage />,
      },
      {
        title: "Chi tiết",
        // path: "/:id",
        path: "/abc123",
        component: <DynamicPage />,
      },
    ],
  },
];

export default routes;
