import
  {
    AutoComplete
  } from "antd";

import { useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import
  {
    createHistoryKeyfindStore, searchBenhLyHeaderStore, setKeySearch
  } from "stores/baiviet";
import i18n from "translation/i18n";
import "./index.css";

const Search = () => {
  let timeout: any = 0;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [value, setValue] = useState("");

  const [loading, setLoading] = useState(false);
  const { Option } = AutoComplete;

  const dispatch = useAppDispatch();
  useEffect(() => {
    setData([]);
    setValue("");
  }, [i18n.language]);

  const handleSearch = async (check: any, e: any) => {

    dispatch(
      await createHistoryKeyfindStore({
        //@ts-ignore
        data: e.key,
      })
    )
      .then(unwrapResult)
      .then((result) => {});

    navigate(`post/${e.key}`);
  };


  const handleClick = async (e: any) => {
    setValue(e);
    if (e) {
      setLoading(true);
      let txt = e;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (txt.length >= 3) {
          dispatch(
            searchBenhLyHeaderStore({
              key_find: txt,
              language: i18n.language,
            })
          )
            .then(unwrapResult)
            .then((result) => {
              setData(result);
              setLoading(false);
            });
        } else {
        }
      }, 500);
    } else {
      setData([]);
    }
  };

  const handleClear = () => {
    setData([]);
  };

  return (
    <div className="divsearch">
      <div className="divtext">{t("titleSearch")}</div>
      <div className="text">{t("titleSearch")}</div>
      <div className="divinput">
        <AutoComplete
          style={{ width: "100%" }}
          onSearch={handleClick}
          placeholder={t("placeholder.search")}
          onSelect={handleSearch}
          //@ts-ignore
          loading={loading}
          allowClear
          onClear={handleClear}
          value={value}
        >
          {data?.map((item: any) => (
            <Option
              key={item._id}
              value={item.original_post}
              onClick={async () => {
                let element = document.getElementById("inputSearch");
                if (element) {
                  //@ts-ignore
                  element.value = item.original_post;
                }
                dispatch(setKeySearch(item.original_post));
                await handleSearch(false, item._id);
              }}
            >
              {item.original_post}
            </Option>
          ))}
        </AutoComplete>
        {/* <Dropdown overlay={menu} trigger={["click"]}>
          <input
            className="inputPost"
            id="inputSearch"
            onChange={(e) => handleClick(e)}
            allowClear
            placeholder={t("placeholder.search")}
            //@ts-ignore
            prefix={<SearchOutlined />}
            // value={key}
          />
        </Dropdown> */}
        {/* <Spin spinning={loading} style={{ marginLeft: "20px" }} /> */}

        {/* <Button
          className="btnLogin"
          type="primary"
          style={{ marginRight: "10px" }}
          onClick={handleSearch}
        >
          {t("button.search")}
        </Button> */}
      </div>
    </div>
  );
};

export default Search;
