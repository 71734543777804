import userReducer from "stores/user";
import baiVietReducer from "stores/baiviet";

const rootReducer = {
  user: userReducer,
  baiviet: baiVietReducer,
  // user: require("stores/user").default, // if  auto import reducer
};

export default rootReducer;
