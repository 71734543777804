import { Button } from "antd";
import moment from "moment";
// import { useModel } from 'umi';
import "./index.css";

const CardDaily = (props: any) => {
  // const { loading, delUserHistoryModel } = useModel('benhly');

  const handleDel = (id: string) => {
    // delUserHistoryModel({ token: localStorage.getItem('token'), id });
  };
  return props?.data?.map((item) => (
    <div className="divCard">
      <>
        <div className="divspacebetween">
          <div>
            <div className="divTimeline">
              {item?.status} {item?.data}
            </div>
            <div className="divTimeline">
              {moment(item.created_at).format("DD/MM/YYYY HH:mm")}
            </div>
          </div>
          <div className="divButton">
            <Button className="btn" onClick={() => handleDel(item._id)}>
              Xóa
            </Button>
          </div>
        </div>
        {/* <hr style={{ border: ' 1px solid #d9d9d9' }} />​ */}
      </>
    </div>
  ));
};

export default CardDaily;
