import ListPost from "components/BaiViet/List";
import Footer from "components/Footer";
import Nav from "components/Nav";
import "./index.css";
import Search from "./search";

const Home = () => {
  return (
    <>
      <div
        style={{
          background: "white",
          minHeight: "100vh",
        }}
      >
        <Nav />
        <div
          className="container"
          style={{
            background: "white",
            minHeight: "calc(100vh - 84px - 55px)",
          }}
        >
          <Search />
          <ListPost />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
