/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import
  {
    Button,
    Dropdown,
    Menu,
    Modal,
    notification,
    Pagination,
    Row,
    Spin,
    Table,
    TableColumnsType,
    Tag
  } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { history, useModel } from "umi";
import { useTranslation } from "react-i18next";
import
  {
    delPostStore,
    editBenhLyStore,
    getBenhLyAdmin, setPage
  } from "stores/baiviet";
import "./index.css";

const ListBenh = () => {
  const { t } = useTranslation();

  const baiViet = useAppSelector((state) => state.baiviet);
  const {  loading, page, postAdmin, totalAdmin } = baiViet;
  const { trangThai, ngonNgu, chuyenKhoa, key_find } = baiViet;
  // console.log(page, "page");
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() =>
  {
    
    //@ts-ignore
    dispatch(
      getBenhLyAdmin({
        //@ts-ignore
        page: page,
        limit: 10,
        language: ngonNgu,
        specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
        key_find: key_find,
        is_active: trangThai,
      })
    )
      .then(unwrapResult)
      .then((result) => {});
  }, [localStorage.getItem("lang")]);

  const handlePaging = async (page: any, pageSize: any) => {
    await dispatch(setPage(page - 1));
    dispatch(
      getBenhLyAdmin({
        //@ts-ignore
        page: page - 1,
        limit: 10,
        language: ngonNgu,
        specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
        key_find: key_find,
        is_active: trangThai,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        dispatch(setPage(page - 1));
      });
  };

  const menu = (record: any) => (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                navigate(`/admin/edit_post/${record?._id}`);
              }}
            >
              Chỉnh sửa
            </a>
          ),
        },

        {
          key: "4",
          label: (
            <a
              onClick={() => {
                navigate(`/admin/copy_post/${record?._id}`);
              }}
            >
              Tạo bản sao
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              onClick={async () => {
                let values = { ...record };

                values.is_active = !record.is_active;

                await dispatch(
                  editBenhLyStore({
                    //@ts-ignore
                    ...values,
                    token: localStorage.getItem("token") ?? null,
                    _id: record?._id,
                    edit: "edit_is_active",
                  })
                )
                  .then(unwrapResult)
                  .then((result) => {
                    notification.success({
                      message: record?.is_active
                        ? `${t("message.sucessHide")}`
                        : `${t("message.sucessRePost")}`,
                    });
                    dispatch(
                      getBenhLyAdmin({
                        //@ts-ignore
                        page: page,
                        limit: 10,
                        language: ngonNgu,
                        specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
                        key_find: key_find,
                        is_active: trangThai,
                      })
                    )
                      .then(unwrapResult)
                      .then((result) => {});
                  });
              }}
            >
              {record?.is_active ? `Tạm ẩn` : `Đăng bài`}
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a
              onClick={() => {
                Modal.confirm({
                  title: `${t("modal.delete.title")}`,
                  icon: <ExclamationCircleOutlined />,
                  content: `${t("modal.delete.content")}`,
                  okText: `${t("button.ok")}`,
                  okType: "danger",
                  okButtonProps: {},
                  cancelText: `${t("button.cancel")}`,
                  onOk() {
                    dispatch(
                      delPostStore({
                        //@ts-ignore
                        token: localStorage.getItem("token") ?? null,
                        post_id: record?._id,
                      })
                    )
                      .then(unwrapResult)
                      .then((result) => {
                        notification.success({
                          message: `${t("message.sucessDel")}`,
                        });

                        dispatch(
                          getBenhLyAdmin({
                            //@ts-ignore
                            page: page,
                            limit: 10,
                            language: ngonNgu,
                            specialization: chuyenKhoa === "Tất cả" ? null : chuyenKhoa,
                            key_find: key_find,
                            is_active: trangThai,
                          })
                        )
                          .then(unwrapResult)
                          .then((result) => {});
                      });
                  },
                });
              }}
            >
              Xóa
            </a>
          ),
        },
      ]}
    />
  );
  const columns: TableColumnsType<any> | undefined = [
    {
      title: `STT`,
      dataIndex: "index",
    },
    {
      title: `Tiêu đề`,
      dataIndex: "original_post",
    },
    {
      title: `Chuyên khoa`,
      dataIndex: "specialization",
      render: (val) => (val ? val?.description : "--"),
      width: "250px",
    },
    {
      title: `Điểm`,
      dataIndex: "point",
      render: (val) => (val?.total ? val?.total : "--"),
      width: "200px",
    },
    {
      title: `Thời gian tạo`,
      dataIndex: "created_at",
      render: (val) => (val ? moment(val).format("HH:mm DD/MM/YYYY") : "--"),
      width: "200px",
    },
    {
      title: `Trạng thái`,
      dataIndex: "is_active",
      render: (val) =>
        val ? (
          <Tag color="#27AE60" className="divTag">
            Đã đăng
          </Tag>
        ) : (
          <Tag color="#8599AD" className="divTag">
            Đã ẩn
          </Tag>
        ),
      width: "150px",
    },
    {
      title: "",
      render: (record) => {
        return (
          <Dropdown overlay={menu(record)} placement="bottomLeft">
            <Button className="btnTable" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <div style={{ paddingTop: "26px", paddingBottom: "25px" }}>
        {postAdmin?.length !== 0 && (
          <>
            <Row gutter={[24, 10]}>
              <Table
                style={{ borderRadius: "8px 8px 0px 0px", width: "100%" }}
                columns={columns}
                dataSource={postAdmin}
                pagination={false}
              />
            </Row>
            <div
              style={{
                float: "right",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Pagination
                // defaultCurrent={1}
                current={page + 1}
                total={totalAdmin}
                onChange={handlePaging}
                pageSize={10}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
        {postAdmin?.length === 0 && (
          /* @ts-ignore */
          <center>
            <img src="/empty.png" />
            {/* @ts-ignore */}
          </center>
        )}
      </div>
    </Spin>
  );
};

export default ListBenh;
