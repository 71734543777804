/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, Modal, Typography } from "antd";
import { useState } from "react";
// import Highlighter from "react-highlight-words";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DetailPost from "./DetailModal";
import "./index.css";

const { Paragraph } = Typography;

const CardItem = (data: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { setKeySearch, keySearch } = useModel('benhly');
  const [visible, setVisible] = useState(false);

  

  return (
    <div>
      <Card
        className="divcard"
        onClick={() => {
          if (window.location.pathname !== "/user/post_saved") {
            navigate(`post/${data?.data?._id}`);
          } else {
            window.open(`/post/${data?.data?._id}`, "_blank");
          }
        }}
      >
        <div className="texttitle">
          <Paragraph
            id="inputText"
            className="texttitle"
            ellipsis={{
              rows: 2,
            }}
          >
            {data?.data?.original_post}
          </Paragraph>
        </div>
        <div className="textcontent">
          {data?.data?.summary?.includes("\n") ? (
            <pre className="pre">
              <Paragraph
                className="textcontent"
                ellipsis={{
                  rows: 3,
                }}
              >
                {data?.data?.summary}
              </Paragraph>
            </pre>
          ) : (
            <Paragraph
              className="textcontent"
              ellipsis={{
                rows: 3,
              }}
            >
              {data?.data?.summary}
            </Paragraph>
          )}
        </div>

        <a className="taga">
          {t("button.seeDetail")} <ArrowRightOutlined />
        </a>
      </Card>

      <Modal
        title={`${t("titleDetail")} "${data?.data?.original_post}"`}
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
        width="90%"
        destroyOnClose
      >
        {/* <FormLogin /> */}
        <DetailPost
          id={data?.data?._id}
          data={data}
          close={() => {
            setVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default CardItem;
