const constants: {
  access: {
    user: Array<string>;
    admin: Array<string>;
  };
} = {
  access: {
    user: [
      "ORG_DICTIONARY",
      "RECEIPTION",
      "PATIENT",
      "ORG_USER",
      "OPERATOR",
      "ORG_OPERATOR",
      "SITE_RECEIPTION",
    ],
    admin: ["ADMIN", "ORG_ADMIN "],
  },
};

export default constants;
