/* eslint-disable jsx-a11y/alt-text */
import { Col, Row, Spin } from "antd";
import { useEffect } from "react";
// import { useModel } from 'umi';
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { getBenhLyStore, searchBenhLyStore } from "stores/baiviet";
import CardItem from "./CardItem";
import "./index.css";

const ListPost = () => {
  const { t, i18n } = useTranslation();

  const baiViet = useAppSelector((state) => state.baiviet);
  const {
    arrPost,
    count,
    limit,
    loadingPost,
    keySearch,
  } = baiViet;

  const dispatch = useAppDispatch();
  useEffect(() => {
    //@ts-ignore
    dispatch(
      getBenhLyStore({
        page: 0,
        limit: 6,
        isActive: true,
        count: count + limit,
        //@ts-ignore
        token: localStorage.getItem("token") ?? null,
        language: i18n.language,
      })
    )
      .then(unwrapResult)
      .then((result) => {});
  }, [localStorage.getItem("lang")]);


  return (
    <Spin spinning={!!loadingPost}>
      <div style={{ paddingTop: "26px" }}>
        <div className="titletext">
          <img src="/trending.png" /> {t("titleList")}
        </div>
        {arrPost?.length !== 0 && (
          <>
            <Row gutter={[10, 10]}>
              {arrPost?.map((item: any) => {
                if (item?.is_active) {
                  return (
                    <Col xs={24} sm={12} md={8}>
                      <CardItem data={item} />
                    </Col>
                  );
                }
              })}
            </Row>
            <Row
              style={{
                float: "right",
                marginTop: "20px",
              }}
            ></Row>
          </>
        )}
        {arrPost?.filter((item) => item.is_active)?.length === 0 && (
          // @ts-ignore
          <center>
            <img src="/empty.png" />
            {/* @ts-ignore */}
          </center>
        )}
      </div>
    </Spin>
  );
};

export default ListPost;
