/* eslint-disable jsx-a11y/alt-text */
import { Button, Modal, notification, Spin } from "antd";
import { useEffect, useState } from "react";
// import { useModel } from 'umi';
import "./index.css";
// import FormLogin from './../Login/loginModal';
import { unwrapResult } from "@reduxjs/toolkit";
import Footer from "components/FooterPost";
import { useAppDispatch, useAppSelector } from "hooks";
import moment from "moment";
import FormLogin from "pages/User/FormLogin";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  delSavedPostStore,
  getBenhLyStore,
  getPostByIdLoadingStore,
  getPostByIdStore,
  getSavedPostStore,
  savePostStore,
  searchBenhLyStore,
  setKeySearch,
  setVisibleMenu,
} from "stores/baiviet";
import { setIsLogin } from "stores/user";
import BaiVietTrending from "./BaiVietTrending";
import LichSu from "./LichSu";
import MucLuc from "./MucLucNew";
import { time } from "console";

const DetailPost = (props: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const baiViet = useAppSelector((state) => state.baiviet);
  const users = useAppSelector((state) => state.user);
  const {
    loading,
    page,
    limit,
    count,
    keySearch,
    loadingPostData,
    visibleMenu,
  } = baiViet;
  const [record, setRecord] = useState<any>({});
  const [dataMenu, setDataMenu] = useState<any>([]);
  const [fontSizeContent, setFontSizeContent] = useState(20);
  const [visible, setVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const winPath = window.location.pathname;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const id = window.location.pathname.split("/")?.[2];
    //@ts-ignore
    dispatch(
      getPostByIdStore({
        //@ts-ignore
        token: localStorage.getItem("token") ?? null,
        post_id: id,
        created_at: moment(),
      })
    )
      .then(unwrapResult)
      .then((result) => {
        setRecord(result);
        setFlag(result?.post_save);

        let a: any[] = [];
        result?.data?.map((item: any, index: any) => {
          a.push({
            key: index,
            label: item?.name,
          });
        });

        setDataMenu(a);
        // localStorage.setItem("post", result?.original_post);
        dispatch(setKeySearch(result?.original_post));
        // result?.data;
      });
  }, [window.location.pathname.split("/")?.[2]]);

  const [flag, setFlag] = useState(false);

  const savePost = async () => {
    if (winPath !== "/user/post_saved") {
      if (!keySearch) {
        if (!flag) {
          dispatch(
            savePostStore({
              //@ts-ignore
              token: localStorage.getItem("token") ?? null,
              post_id: record?._id,
            })
          )
            .then(unwrapResult)
            .then((result) => {
              notification.success({
                message: "Lưu bài viết thành công",
                duration: 3,
              });
              dispatch(
                getBenhLyStore({
                  //@ts-ignore
                  token: localStorage.getItem("token") ?? null,
                  isActive: true,
                  page: page,
                  limit: limit,
                  count: count,
                })
              )
                .then(unwrapResult)
                .then((result) => {});
            });

          setFlag(true);
        } else {
          dispatch(
            delSavedPostStore({
              //@ts-ignore
              token: localStorage.getItem("token") ?? null,
              post_id: record?._id,
            })
          )
            .then(unwrapResult)
            .then((result) => {
              notification.success({
                message: "Bỏ lưu bài viết thành công",
                duration: 3,
              });
              dispatch(
                getBenhLyStore({
                  //@ts-ignore
                  token: localStorage.getItem("token") ?? null,
                  isActive: true,
                  page: page,
                  limit: limit,
                  count: count,
                })
              )
                .then(unwrapResult)
                .then((result) => {});
            });

          setFlag(false);
        }
      } else {
        if (!flag) {
          dispatch(
            savePostStore({
              //@ts-ignore
              token: localStorage.getItem("token") ?? null,
              post_id: record?._id,
            })
          )
            .then(unwrapResult)
            .then((result) => {
              notification.success({
                message: "Lưu bài viết thành công",
                duration: 3,
              });
              dispatch(
                searchBenhLyStore({
                  //@ts-ignore
                  count: 12,

                  //@ts-ignore
                  token: localStorage.getItem("token") ?? null,
                  key_find: keySearch,
                  sick: ["sick"],
                  authorities: ["ROLE_USER"],
                  post_id: "",
                  page: 0,
                  limit: 12,
                })
              )
                .then(unwrapResult)
                .then((result) => {});
            });

          setFlag(true);
        } else {
          dispatch(
            delSavedPostStore({
              //@ts-ignore
              token: localStorage.getItem("token") ?? null,
              post_id: record?._id,
            })
          )
            .then(unwrapResult)
            .then((result) => {
              notification.success({
                message: "Bỏ lưu bài viết thành công",
                duration: 3,
              });
              dispatch(
                searchBenhLyStore({
                  //@ts-ignore
                  count: 12,

                  //@ts-ignore
                  token: localStorage.getItem("token") ?? null,
                  key_find: keySearch,
                  sick: ["sick"],
                  authorities: ["ROLE_USER"],
                  post_id: "",
                  page: 0,
                  limit: 12,
                })
              )
                .then(unwrapResult)
                .then((result) => {});
            });

          setFlag(false);
        }
      }
    } else {
      if (!flag) {
        dispatch(
          savePostStore({
            //@ts-ignore
            token: localStorage.getItem("token") ?? null,
            post_id: record?._id,
          })
        )
          .then(unwrapResult)
          .then((result) => {
            notification.success({
              message: "Lưu bài viết thành công",
              duration: 3,
            });
            dispatch(
              getSavedPostStore({
                //@ts-ignore
                token: localStorage.getItem("token") ?? null,
                isActive: true,
                page: page,
                limit: limit,
                count: count,
              })
            )
              .then(unwrapResult)
              .then((result) => {});
          });

        setFlag(true);
      } else {
        dispatch(
          delSavedPostStore({
            //@ts-ignore
            token: localStorage.getItem("token") ?? null,
            post_id: record?._id,
          })
        )
          .then(unwrapResult)
          .then((result) => {
            notification.success({
              message: "Bỏ lưu bài viết thành công",
              duration: 3,
            });
            setTimeout(() => {
              dispatch(
                getSavedPostStore({
                  //@ts-ignore
                  token: localStorage.getItem("token") ?? null,
                  isActive: true,
                  page: page,
                  limit: limit,
                  count: count,
                })
              )
                .then(unwrapResult)
                .then((result) => {});
            }, 250);
          })
          .finally(() => {
            props.close();
          });
        setFlag(false);
      }
    }
  };

  const cancelModal = () => {
    dispatch(setIsLogin(false));
  };

  const handleUrl = (key: any) => {
    let url = "";
    switch (key) {
      case "Khoa Nội tổng hợp":
        url = "/K03.png";
        break;

      case "Khoa Nội cơ - xương - khớp":
        url = "/K06.png";
        break;

      case "Khoa Nội tiết":
        url = "/K08.png";
        break;

      case "Khoa Da liễu":
        url = "/K13.png";
        break;

      case "Khoa Lão học":
        url = "/K17.png";
        break;

      case "Khoa Nhi":
        url = "/K18.png";
        break;

      case "Khoa Ngoại tổng hợp":
        url = "/K19.png";
        break;

      case "Khoa Phụ sản":
        url = "/K27.png";
        break;

      case "Khoa Tai - Mũi - Họng":
        url = "/K28.png";
        break;

      case "Khoa Răng - Hàm - Mặt":
        url = "/K29.png";
        break;

      case "Khoa Mắt":
        url = "/K30.png";
        break;

      case "Khoa Chẩn đoán hình ảnh":
        url = "/K39.png";
        break;
      default:
        url = "/icon.png";
        break;
    }
    return url;
  };

  const highlightQuery = (name: any, query: any) => {
    const arr = ["-", "/", "\\", ";", "."];
    try {
      if (name.includes("<img src=")) {
        return name;
      } else {
        let a = query.split(" ");
        for (let item of a) {
          if (!arr.includes(item)) {
            var regex = new RegExp("(" + item + ")", "gi");
            name = name.replace(
              regex,
              '<span style="background-color: #FFFF00">' + item + "</span>"
            );
          }
        }
        return name;
      }
    } catch (error) {
      return name;
    }
  };

  const handleTranPost = () => {
    // dispatch(setKeySearch(record?.original_post));
    // navigate(`../post/${record?.post_link}`);
    // localStorage.setItem("post", record?.original_post);

    // navigate(0);

    dispatch(
      getPostByIdLoadingStore({
        //@ts-ignore
        token: localStorage.getItem("token") ?? null,
        post_id: record?.post_link,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        setRecord(result);
        setFlag(result?.post_save);
        let a: any[] = [];
        result?.data?.map((item: any, index: any) => {
          a.push({
            key: index,
            label: item?.name,
          });
        });

        setDataMenu(a);
        // localStorage.setItem("post", result?.original_post);
        // dispatch(setKeySearch(result?.original_post));
        // result?.data;
      });
  };

  const handleOut = () => {
    setVisible(visible === false && visibleMenu ? visible : !visible);
    dispatch(setVisibleMenu(!visibleMenu));
  };

  const replaceALink = (str: string) => {
    if (str) {
      return str.replaceAll("<a", `<a target="_blank"`);
    }
  };

  return (
    <>
      <Spin spinning={!!loading}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <MucLuc
                data={dataMenu}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                visibleMenu={visibleMenu}
              />
            </div>

            <div className="divPost">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "white",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                  // position: "sticky",
                  position: "fixed",
                  top: !visibleMenu ? 0 : 90,
                  // // marginBottom: "20px",
                  zIndex: 999,
                  //@ts-ignore
                  width: `calc(100vw - ${!collapsed ? "245px" : "100px"} - ${
                    visible ? "245px" : "53px"
                  })`,
                }}
              >
                <div>
                  {record?.post_link !== "" ? (
                    <>
                      <Button
                        className={
                          record.language === "vi"
                            ? "buttonLangActive"
                            : "buttonLang"
                        }
                        style={{ marginRight: "7px" }}
                        onClick={handleTranPost}
                        // disabled={record.language === "vi"}
                      >
                        {t("button.vi")}
                      </Button>
                      <Button
                        className={
                          record.language === "en"
                            ? "buttonLangActive"
                            : "buttonLang"
                        }
                        // disabled={record.language === "en"}
                        onClick={handleTranPost}
                      >
                        {t("button.en")}
                      </Button>
                    </>
                  ) : record.language === "en" ? (
                    <Button
                      className="buttonLangActive"
                      disabled
                      onClick={handleTranPost}
                    >
                      {t("button.en")}
                    </Button>
                  ) : (
                    <Button
                      className="buttonLangActive"
                      disabled
                      onClick={handleTranPost}
                    >
                      {t("button.vi")}
                    </Button>
                  )}
                </div>

                <div style={{ float: "right", display: "flex" }}>
                  <Button
                    className="buttonFont"
                    icon={<img src="/add.png" style={{ marginRight: "5px" }} />}
                    style={{ marginRight: "7px" }}
                    onClick={() => setFontSizeContent(fontSizeContent + 1)}
                  >
                    {t("button.zoomout")}
                  </Button>
                  <Button
                    className="buttonFont"
                    icon={
                      <img src="/minus.png" style={{ marginRight: "5px" }} />
                    }
                    onClick={() => setFontSizeContent(fontSizeContent - 1)}
                  >
                    {t("button.zoomin")}
                  </Button>
                  <span onClick={handleOut} style={{ marginLeft: "7px" }}>
                    <img src={visibleMenu ? "/out.png" : "/in.png"} />
                  </span>
                  {token && (
                    <span
                      onClick={savePost}
                      style={{ marginLeft: "7px", marginRight: "7px" }}
                    >
                      {!flag ? (
                        <img src="/notsave.png" />
                      ) : (
                        <img src="/saved.png" />
                      )}
                    </span>
                  )}

                  {localStorage.getItem("user_type") === "ADMIN" && (
                    <span
                      style={{ marginLeft: "7px", marginRight: "7px" }}
                      onClick={() =>
                        navigate(`../admin/edit_post/${record?._id}`)
                      }
                    >
                      <img src="/edit.png" />
                    </span>
                  )}
                </div>
              </div>

              <Spin spinning={!!loadingPostData}>
                <div className="divtitle">
                  <div style={{ display: "flex" }}>
                    <div
                      className="texttitle"
                      style={{
                        fontSize: "26px",
                        lineHeight: "30px",
                        marginTop: !visibleMenu ? "55px" : "135px",
                      }}
                      // id="0"
                    >
                      <div
                        id="0"
                        style={{
                          height: "150px",
                          width: "100%",
                          position: "absolute",
                          top: "-150px",
                        }}
                      ></div>
                      {/*  @ts-ignore*/}
                      {keySearch ? (
                        <Highlighter
                          highlightClassName="highlight"
                          searchWords={keySearch.split(" ")}
                          autoEscape={true}
                          textToHighlight={
                            record?.original_post ??
                            props?.data?.data?.original_post
                          }
                        />
                      ) : (
                        <span>{record?.original_post}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "5px" }}>
                  <p style={{ color: "#1F2D3D" }}>
                    {t("label.createdAt")}{" "}
                    {moment(record?.created_at).format("HH:mm DD/MM/yyyy")}
                    {/* <span style={{ marginLeft: "35px" }}>
                    Danh mục: <u>Bài viết</u>
                  </span> */}
                  </p>
                </div>

                {!token && (
                  <>
                    <div style={{ marginTop: "15px" }}>
                      {/*  @ts-ignore*/}
                      {record?.data?.map((item: any, index: any) => (
                        <>
                          <>
                            <div style={{ position: "relative" }}>
                              <div
                                id={index === 0 ? "abc" : index}
                                style={{
                                  height: "150px",
                                  width: "100%",
                                  position: "absolute",
                                  top: "-150px",
                                }}
                              ></div>
                              <b style={{ fontSize: fontSizeContent }}>
                                {item?.name}
                              </b>

                              <div
                                style={{
                                  textAlign: "justify",
                                  fontSize: fontSizeContent,
                                }}
                                dangerouslySetInnerHTML={{
                                  // __html: keySearch
                                  //   ? highlightQuery(item.content, keySearch)
                                  //   : item.content,
                                  // @ts-ignore
                                  __html: replaceALink(item?.content), // đoạn này là render cái html đó ra lè
                                }}
                              />
                            </div>
                          </>
                        </>
                      ))}
                    </div>
                    <div
                      style={{ marginTop: "10px", cursor: "pointer" }}
                      onClick={() => dispatch(setIsLogin(true))}
                    >
                      <a style={{ cursor: "pointer" }}>
                        <i>{t("button.loginMore")}</i>
                      </a>
                      <Button
                        type="primary"
                        className="btnLogin"
                        style={{ marginLeft: "10px" }}
                      >
                        {t("button.login")}
                      </Button>
                    </div>
                  </>
                )}

                {token &&
                  // @ts-ignore
                  record?.data?.map((item: any, index: any) => (
                    <>
                      <div style={{ position: "relative" }}>
                        <div
                          id={index === 0 ? "abc" : index}
                          style={{
                            height: "170px",
                            width: "100%",
                            position: "absolute",
                            top: "-170px",
                          }}
                        ></div>
                        <b style={{ fontSize: fontSizeContent }}>
                          {item?.name}
                        </b>

                        <div
                          style={{
                            textAlign: "justify",
                            fontSize: fontSizeContent,
                          }}
                          dangerouslySetInnerHTML={{
                            // __html: keySearch
                            //   ? highlightQuery(item.content, keySearch)
                            //   : item.content,
                            // @ts-ignore
                            __html: replaceALink(item?.content),
                          }}
                        />
                      </div>
                    </>
                  ))}
              </Spin>

              <Modal
                title={t("titleModalLogin")}
                visible={users.isLogin}
                footer={null}
                onCancel={() => dispatch(setIsLogin(false))}
              >
                <FormLogin cancel={cancelModal} id={props.id} />
              </Modal>
            </div>

            <div
              style={{
                marginTop: !visibleMenu ? "20px" : "110px",
                paddingRight: "20px",
              }}
            >
              <svg
                style={{
                  position: visible ? "absolute" : "initial",
                  // right: 210,\
                  // position: "fixed", // cái này em ko cần fix mà

                  right: visible ? "210" : "30",
                  zIndex: 9999,
                }}
                xmlns="http://www.w3.org/2000/svg"
                width={36}
                height={36}
                viewBox="0 0 36 36"
                fill="none"
                onClick={() => setVisible(!visible)}
              >
                <g filter="url(#filter0_d_553_13893)">
                  <rect
                    x={4}
                    y={2}
                    width={28}
                    height={28}
                    rx={14}
                    fill="white"
                    shapeRendering="crispEdges"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4578 12.4353C14.6593 12.2464 14.9757 12.2566 15.1646 12.4581L18.1646 15.6581C18.3449 15.8504 18.3449 16.1497 18.1646 16.342L15.1646 19.542C14.9757 19.7435 14.6593 19.7537 14.4578 19.5648C14.2564 19.376 14.2462 19.0595 14.435 18.8581L17.1144 16.0001L14.435 13.142C14.2462 12.9406 14.2564 12.6241 14.4578 12.4353Z"
                    fill="#5A6B81"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.8582 12.4353C18.0597 12.2464 18.3761 12.2566 18.565 12.4581L21.565 15.6581C21.7453 15.8504 21.7453 16.1497 21.565 16.342L18.565 19.542C18.3761 19.7435 18.0597 19.7537 17.8582 19.5648C17.6568 19.376 17.6466 19.0595 17.8354 18.8581L20.5148 16.0001L17.8354 13.142C17.6466 12.9406 17.6568 12.6241 17.8582 12.4353Z"
                    fill="#5A6B81"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_553_13893"
                    x={0}
                    y={0}
                    width={36}
                    height={36}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy={2} />
                    <feGaussianBlur stdDeviation={2} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.870677 0 0 0 0 0.884618 0 0 0 0 0.9125 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_553_13893"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_553_13893"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>

              {visible && (
                <>
                  {localStorage.getItem("token") && <LichSu />}

                  <BaiVietTrending />
                </>
              )}
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DetailPost;
